@use "../../fileSass/variables";

//formResponsive-fullWidth
//formResponsive--widthFull
//formResponsive-fullWidthColumn
//formResponsive-btn
//formResponsive-startAndEndTime
//formResponsive-fullWidthCenter
//formResponsive-double
//formResponsive-triple
//formResponsive-failed
//formResponsive-small

%formElementsList {
  @extend %displayWrapBetween;
  .formTextInput,
  .formSelectBox,
  .formInputNumber,
  .formSwitch,
  .formCheckBox,
  .formTimePicker,
  .formDatePicker,
  .formDateAndTimePicker,
  .formRadioGroup,
  .formResponsive-startAndEndTime,
  & > .formMask {
    width: 48%;
  }
}

.formResponsive--modal {
  @extend %formElementsList;
}

.formResponsive {
  &-btn {
    width: 100%;
    @extend %displayCenterCenter;
    .formSubmitButton {
      width: auto;
      // margin: 0 0 0 10px;
    }
  }
  &--widthFull {
    width: 100% !important;
  }
  &-fullWidth {
    width: 100% !important;
    display: flex;
  }

  &-fullWidthColumn {
    width: 100% !important;
    @extend %displayColumn;
  }
  &-fullWidthCenter {
    width: 100% !important;
    @extend %displayCenterCenter;
  }
  &-startAndEndTime {
    @extend %displayWrapBetween;
    .formTimePicker__datePicker {
      width: 100%;
    }
  }

  &-double {
    @extend %formElementsList;
  }

  &-triple {
    @extend %displayWrapBetween;
    .formTextInput,
    .formSelectBox,
    .formInputNumber,
    .formSwitch,
    .formCheckBox,
    .formTimePicker,
    .formDatePicker,
    .formDateAndTimePicker,
    .formRadioGroup,
    .formResponsive-startAndEndTime,
    & > .formMask {
      width: 31%;
    }
  }
  &-failed {
    text-align: center;
    color: variables.$error-red;
    margin: 10px 0;
  }

  &-small {
    max-width: 255px;
  }

  &-columns {
    gap: 4px;
    display: flex;
  }

}

.hidden-form-element{
  display: none;
}


