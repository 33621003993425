@use "../../fileSass/media";
@use "../../fileSass/variables";


.drawerResourceCard {
  min-height: 64px;
  // max-height: 80px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 12px 20px 12px 14px;
  padding: 8px 8px 8px 8px;
  margin-bottom: 8px;
  border: 1px solid rgba(205, 205, 205, 0.46);
  background: rgba(246, 246, 246, 0.29);

  @include media.media479 {
    padding: 12px 10px;
  }

  &--leftIcon {
    min-width: 42px;
    max-width: 104px;
    display: flex;
    justify-content: center;

    img {
      object-fit: cover;
    }

    &-iconContainer {
      width:  86px;
      height: 60px;
      display: flex;
      justify-content:center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid variables.$ebaq-gray4;
    ;
    }

    &-icon {
      svg {
        //width: 24px;
        font-size: 26px;
        color: variables.$ebaq-gray4!important;
      }
    }
  }

  &--rightIcon {
    margin-left: 10px;
    cursor: pointer;


    svg {
      color: variables.$ebaq-clickable-text!important;
    }


  }

  &-container {
    flex: 1;
    // cursor: pointer;
    display: flex;
    flex-direction: column;
    // gap: 4px;
    padding-left: 8px;
    &-desc {
      font-size: 10px;
      color: #9b9b9b;

      &-team-policy {
        font-size: 10px;
        color: variables.$ebaq-clickable-text-light;
      }
      &-team-desc {
        font-size: 10px;
        // font-weight: 600;
        color: variables.$ebaq-clickable-text-muted;
      }
    }

    &-resource {
      padding-left:1px;
      svg {
        font-size: 9px!important;
        color: #9b9b9b;
      }
      span {
        font-size: 10px!important;
        color: #9b9b9b;
      }
    }

    &-rname {
      font-size: 14px;
      font-weight: 600;
      color: variables.$ebaq-clickable-text;
    }

    &-companyType {
      margin-top: 2px;
      display: flex;
      justify-content: center;
      font-size: 9px;
      font-weight: 600;
      padding: 2px;
      border: 0.5px solid variables.$ebaq-gray6;
      border-radius: 4px;
      max-width: 100px;

      color: variables.$ebaq-gray6;
    }
  }



}
