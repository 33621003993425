@use "../../fileSass/media";
@use "../../fileSass/variables";

.workCard {
  min-height: 64px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 12px 20px 12px 14px;
  padding: 10px 8px 10px 14px;
  margin-bottom: 12px;
  border: 1px solid rgb(205 205 205);

  @include media.media479 {
    padding: 12px 10px;
  }

  &--leftIcon {
    margin-right: 13px;

    svg {
      width: 24px;
      font-size: 24px;
    }
  }

  &--rightIcon {
    margin-left: 10px;
  }

  &-container {
    width: 100%;
    line-height: 20px;

    p,
    h4,
    .icon {
      line-height: 20px;
    }
  }

  // gray
  &-gray {
    background: #f2f2f2;

    p,
    h4,
    svg {
      color: #4f4f4f;
    }

    h4 {
      font-size: 10px;
      color: #9b9b9b;
    }

    p {
      font-size: 13px;
      font-weight: 600;

      @include media.media479 {
        font-size: 13px;
      }
    }

    .workCard--leftIcon {
      svg {
        color: variables.$fontColor-orange-muted-70;
      }
    }
  }

}
