@use "../../fileSass/media";

.cardMessages {
  &-infoGray {
    background: #f2f2f2;
    // padding: 10px 16px 16px;
    padding: 10px 8px 10px 14px;
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    h2 {
      border-radius: 4px;
      color: #828282;
      margin-bottom: 9px;
      // line-height: 19px;
      font-weight: 600;
      font-size: 14px;
    }

    &-container {
      @extend %displayCenter;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 4px;
      .icon {
        margin-right: 14px;
        // line-height: 20px;
        svg {
          font-size: 25px;
          color: #bdbdbd;
        }
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: #4f4f4f;
      }
    }
  }

  &-infoBlue {
    background: linear-gradient(90.16deg, #264294 13.61%, #456cdf 92.98%);
    //padding: 16px;
    // padding: 10px 16px 16px;
    padding: 10px 8px 10px 14px;
    min-height: 64px;
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &-container {
      display: flex;
      flex: 1;
      // flex-direction: column;
      align-items: center;
      margin-left: 4px;
      .icon {
        padding-top: 6px;
        margin-right: 14px;
        // line-height: 20px;
        svg {
          font-size: 25px;
          color: #a3bbff!important;
        }
      }

      h4 {
        line-height: 20px;
        font-size: 10px;
        color: #a3bbff;
      }

      p {
        font-size: 14px;
        font-weight: bold;
        @include media.media479 {
          font-size: 13px;
        }
        // line-height: 20px;
        // color: white;
      }
    }

    .icon {
      // margin-right: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      svg {
        font-size: 25px;
        color: white;
      }
    }
  }
}
