@use "../../fileSass/variables";

.infoCard {
  display: flex;
  justify-content: space-between;
  padding: 20px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  box-sizing: border-box;

  &--title {
    color: variables.$ebaq-gray3;
    font-weight: 600;
    font-size: 12px;
  }

  &--value {
    color: variables.$ebaq-black;
    font-weight: 600;
    font-size: 14px;
  }

}
