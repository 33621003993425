@use "../../fileSass/variables";

.formListInputs {
  width: 100%;

  &--label {
    margin-bottom: 12px !important;
    font-weight: bold;
    font-size: 14px;
  }

  &-list {
    > div {
      &:last-child {
        margin-top: 6px;
        margin-bottom: variables.$form-input-margin-bottom;
        height: 32px;

        .ant-form-item-control-input,
        .ant-form-item-control-input-content,
        button {
          border-radius: 4px;
        }
        button {
          padding-top: 4px;
          color: variables.$form-fontColor;
          span {
            font-size: variables.$form-fontSize;
          }
          &:hover,
          &:focus {
            color: variables.$ebaq-orange;
            border-color: variables.$ebaq-orange;
            span {
              color: variables.$ebaq-orange;
            }
          }
        }
      }
    }

    .formTextInput,
    .formTimePicker,
    .formInputNumber,
    .formDatePicker,
    .formSelectBox {
      margin-right: 4px;
      .ant-form-item {
        margin-bottom:2px;
      }
    }

    &-item {
      @extend %displayBetweenCenter;

      &-close {
        bottom: 0;
        width: 44px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // border-left: 1px solid variables.$ebaq-orange !important;
        cursor: pointer;

        .item-remove {
          margin-top: 18px;
          font-size: 18px;
          color: variables.$ebaq-orange;
        }
      }

      &--labelClose {
        align-items: flex-start;

        .formListInputs-list-item-close {
          margin-top: 9px;
        }
      }
    }
  }
}
