@use "../../fileSass/variables";

.formSwitch {
  .ant-switch-checked {
    background-color: variables.$form-switch-bg;
  }
  &--green {
    margin-bottom: variables.$form-input-margin-bottom;
    > div {
      display: flex;
      align-items: center;
      .ant-form-item {
        &-control-input {
          min-height: 25px;
        }
        margin-bottom: 0;
        margin-right: 8px;
      }

      .formElementLabel{
        margin-bottom: 0 !important;
      }
    }
    .ant-switch {
      height: 16px;
      border: 1px solid #c9c9c9;
      min-width: 32px;
      background: white;
      &-handle {
        width: 10px;
        height: 10px;
        &::before {
          background-color: variables.$form-switch-bg-green-not-selected;
        }
      }
    }
    .ant-switch-checked {
      background-color: variables.$form-switch-bg-green;
      border: 1px solid variables.$form-switch-bg-green;
      .ant-switch-handle {
        left: calc(100% - 10px - 2px);
        &::before {
          background-color: white;
        }
      }
    }
  }

  &--red {
    margin-bottom: variables.$form-input-margin-bottom;
    > div {
      display: flex;
      .ant-form-item {
        &-control-input {
          min-height: 25px;
        }
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
    .ant-switch {
      height: 16px;
      border: 1px solid #c9c9c9;
      min-width: 32px;
      background: white;
      &-handle {
        width: 10px;
        height: 10px;
        &::before {
          background-color: variables.$form-switch-bg-red;
        }
      }
    }
    .ant-switch-checked {
      background-color: variables.$form-switch-bg-red;
      border: 1px solid variables.$form-switch-bg-red;
      .ant-switch-handle {
        left: calc(100% - 10px - 2px);
        &::before {
          background-color: white;
        }
      }
    }
  }
}
