@use "../../fileSass/variables";

.formCheckBox {
  &__label {
    font-weight: 400;
    font-size: variables.$form-fontSize;
    color: variables.$form-fontColor;
    line-height: 1.64;

    &.required {
      &:after {
        content: " *";
        color: variables.$error-red;
      }
    }
  }

  .ant-checkbox-wrapper {
    @extend %displayCenter;
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: solid 1px variables.$form-border;
      z-index: 1;
      &:hover,
      &:focus {
        border: solid 1px variables.$form-border-focus !important;
      }
    }

    .ant-checkbox {
      &::after {
        border: none !important;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: variables.$form-checkBox-bg;
      &::after {
        left: 25%;
      }
    }
  }

  //contract
  &-contractActive {
    text-decoration: underline;
  }
}
