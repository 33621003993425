@use "../../fileSass/media";
@use "../../fileSass/variables";

.addCard {
  @extend %displayFull;
  @extend %slow300;
  justify-content: normal;
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  border:1px solid rgba(0, 0, 0, 0.2);
  cursor: default;
  width: 19%;
  min-width: 120px;
  max-width: 240px;
  // margin: 12px 0.5%;
  min-height: 64px;

  // padding: 1rem;
  //padding: 6px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 6px;
  padding-bottom: 6px;
  @include media.media767 {
    // min-width: 128px;
     width: 32%;
    padding: 10px;
    // padding: 16px 5px 10px 5px;
  }
  @include media.media479 {
    width: 48%;
    padding: 8px 20px;
  }


  &-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    display: flex;
    min-width: 300px;
    // flex-wrap: wrap;
    justify-content: space-between;
    gap: 6px;
    align-items: center;


    @include media.media767 {
      flex-wrap: wrap;
      // gap:4px;
    }

  }
  &__addBtn {
    position: absolute;
    top: 5px;
    right: 7px;
    @include media.media767 {
      top: 2px;
      right: 1px;
    }
    .iconify {
      font-size: 35px;
      color: variables.$bg-green;
      @include media.media1023 {
        font-size: 32px;
      }
      @include media.media767 {
        font-size: 22px;
      }
      @include media.media479 {
        font-size: 20px;
      }
    }
  }
  &-top {
    @extend %displayFull;
    @include media.media767 {
      flex-direction: row;
    }

    &__icon {
      color: variables.$ebaq-orange;

      svg{

        font-size: 20px;

        path {
          // stroke: variables.$ebaq-orange;
          color: variables.$ebaq-orange;
        }
      }

      @include media.media767 {
        margin-bottom: 0;
        margin-right: 5px;
      }
      &.active {
        color: variables.$ebaq-orange-muted-70;
      }

      .iconify {
        font-size: 22px;
        // @include media.media1023 {
        //   font-size: 22px;
        // }
        // @include media.media767 {
        //   font-size: 20px;
        // }
        // @include media.media479 {
        //   font-size: 18px;
        // }
      }
    }
    &-count {
      position: relative;
      &__amount {
        color: variables.$fontColor-primary;
        font-weight: 600;
        font-size: 20px;
        @include media.media1023 {
          font-size: 22px;
        }
        @include media.media767 {
          font-size: 20px;
        }
        @include media.media479 {
          font-size: 18px;
        }
      }
      &__loading {
        color: variables.$fontColor-primary;
        font-size: 12px;
      }
      &__arrow {
        position: absolute;
        top: 0;
        &.up {
          transform: rotate(-90deg);
          color: variables.$bg-green;
        }
        &.down {
          transform: rotate(90deg);
          color: variables.$bg-red;
        }
        .iconify {
          @include media.media1023 {
            font-size: 22px;
          }
          @include media.media767 {
            font-size: 20px;
          }
          @include media.media479 {
            font-size: 18px;
          }
        }
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 10px;
    margin-top: 12px;
    color: rgb(162, 162, 162);
    line-height: 1.2rem;
    text-align: center;

    @include media.media767 {
      font-size: 10px;
    }
    @include media.media479 {
      font-size: 8px;
    }
  }
}


.cursorWithHover {
  cursor: pointer;
  &:hover {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  }

}

.ant-segmented-item-selected {
  border-radius: 4px;
}