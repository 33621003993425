.site-tree-search-value {
  color: #f50;
  font-weight: bold;
}


.category-title {
  padding-top: 3px;
  padding-bottom: 3px;

  .category-title-sub {
    margin-top: 2px;
    color: #b1b1b1;
    font-size: 10px !important;
    span {
      font-size: 12px !important;
    }
  }

  .category-title-desc {
    margin-top: 2px;
    color: #b1b1b1;
    font-size: 12px;

  }

}


