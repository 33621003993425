@use "../../fileSass/variables";

.formTextInput {
  width: 100%;

  &-inner {
    display: flex;
    width: 100%;

    .ant-form-item {
      width: 100%;
      margin-bottom: variables.$form-input-margin-bottom;
    }
  }

  &__input {
    border: solid 1px variables.$form-border;
    background-color: variables.$form-bg !important;
    color: variables.$form-fontColor;
    line-height: variables.$form-height;
    font-size: variables.$form-fontSize;
    height: variables.$form-height;
    // margin-top: 6px;
    border-radius: 4px;
    padding: 0 12px;

    &:focus {
      border: solid 1px variables.$form-border-focus !important;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
    }

    &:hover {
      border: solid 1px variables.$form-border-focus !important;
    }
  }

  &--password {
    input {
      color: variables.$form-fontColor;
      line-height: 1.44;
      font-weight: 500;
      font-size: variables.$form-fontSize;
      background-color: variables.$form-bg !important;
    }

    .formTextInput__input {
      padding-top: 0;
    }

    .ant-input-suffix {
      svg {
        font-size: 20px;
      }
    }
  }

  .ant-input-password {
    box-shadow: none;
  }

  .ant-input {

    &-affix-wrapper-focused {
      border: solid 1px variables.$form-border-focus !important;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
    }

    &-prefix {
      padding-right: 10px;
      border-right: 1px solid variables.$fontColor-orange;
      margin: 2px 5px 2px 0;
      svg {
        font-size: 14px;
      }
    }
  }

  .ant-input[disabled] {
    background-color: #f5f5f5 !important;
  }
}
