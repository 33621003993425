@use "../../fileSass/variables";

$orange: #ff9212;
.formUploadFile {
  width: auto;
  margin: 0.3rem 0rem 0rem;
  border-radius: 4px;
  // width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #fff;
  font-weight: 500;
  font-size: variables.$form-fontSize;

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  &-container:hover {
    background-color: #33333310;
  }
  &-file-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    line-height: normal;
    margin-right: 0.7rem;
    flex: 1;
    display: block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    &-error {
      margin-top: 0.4rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &-icon {
    margin-right: 0.5rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    color: #828282;
    svg {
          font-size: 14px;
          // color: #bdbdbd;
        }
    @extend %slow300;

  }
  &-cancelUpload {
  }
  &-delete-button {
    margin-left: 0.6rem;
    @extend %displayEnd;
  }
  &-disabled {
    font-size: 0.8rem;
    margin-top: 0.15rem;
  }
  &-upload-button {
    color: #ffffff;
  }
  &-progress {
    flex: 1;
    display:flex;
    flex-direction: row;
    margin-left: 0.5rem;
  }
}

.formList {
  width: auto;
  height: 100vh;
  overflow-y: auto;
  @extend %displayColumn;
}

.ant-upload {
  &-text{
    font-size:13px!important;
  }
  &-hint{
    font-size:10px!important;
  }
}

.ant-list {
  &-empty-text{
    font-size:10px!important;
  }
}

.ant-progress {
  display:flex;
  &-line{
    font-size:12px!important;
  }
}
