@use "../../fileSass/variables";

.formTextArea {
  width: 100%;

  &__textarea {
    @extend %scrollYBlue;
    // resize: none;
    // min-height: $form-textArea-height !important;
    // margin-top: 6px;
    border-radius: 4px;
    //background-color: $form-bg !important;
    color: variables.$form-fontColor;
    line-height: 1.44;
    font-size: variables.$form-fontSize;
    overflow-y: hidden !important;

  }

  .ant-input-textarea-show-count::after {
    margin-bottom: 0 !important;
  }
}
