@use "../../fileSass/variables";

.formMultiUploadFile {
  width: 100%;
  @extend %displayColumn;

  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 4px 4px 8px;
    gap:4px;
    // border: solid 1px variables.$form-border;
    // border-radius: 6px;
      // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    .ant-form-item {
      margin-bottom: 0!important;
    }

    .ant-form-item-control-input {
      min-height: 0;
    }
  }

  &-container {
    @extend %displayCenter;
    // margin-bottom: 12px;
    // margin-top: 6px;
    // height: $form-height;
    // border-radius: 4px;
    width: 150px;
    // border: solid 1px $form-border;
    // background-color: #fff;
    // padding: 3px;
    background-color: variables.$form-bg;
    color: variables.$form-fontColor;
    line-height: 1.44;
    font-weight: 500;
    font-size: variables.$form-fontSize;

    &:focus {
      border: solid 1px variables.$form-border-focus;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    }

    &:hover {
      //  border: solid 1px $form-border-focus;
    }

    &-btn {
      cursor: pointer;
      position: relative;
      color: #bfbfbf;
      background-color: variables.$form-bg;
      // height: $form-height - 2;
      overflow: hidden;
      // width: 150px;

      &:hover {
          color: variables.$ebaq-orange;
          .icon svg {
            color: variables.$ebaq-orange;
          }
          .formMultiUploadFile-container-btn__name {
            color: variables.$ebaq-orange;
          }
        }

      &__name {
        font-size: 11px;
        color: #bfbfbf;
        pointer-events: none;
        margin-left: 4px;

      }
      @extend %displayCenterCenter;
      .anticon {
        margin-right: 10px;
        color: #BDBDBD;
      }
      & input[type="file"] {
        cursor: pointer;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 150px;
      }
    }
  }

  &-file {
    max-width: 100%;
    @extend %displayCenter;
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 0 15px 0 10px;
    cursor: pointer;
    border: 1px solid variables.$form-border;
    background-color: variables.$form-bg;
    &:hover {
      .formMultiUploadFile-file-close__icon {
        color: #403e3e;
      }
    }

    &-close {
      border-left: 1px solid #ff9212 !important ;
      padding-left: 14px;
      margin: 5px 0;
      cursor: pointer;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconify {
        // width: 22px;
        font-size: 16px;
      }
    }

    &-container {
      @extend %displayCenter;
      overflow: hidden;
      max-width: 100%;
      background-color: variables.$form-bg;
      width: 100%;
      height: variables.$form-height;
      cursor: pointer;

      span {
        font-size: variables.$form-fontSize;
      }
      > span {
        padding: 5px 10px 5px 10px;
      }
    }

    &-fileInfo {
      display: flex;
      flex-direction: column;

      padding-left: 4px;

      &-size {
        color: variables.$form-labelColor;
        font-size: variables.$form-smallLabelFontSize!important;
      }
    }


  }

  &-actionButtonsContainer {
    display: flex;
    justify-content: space-between;

  }
}
