@use "../../fileSass/media";
@use "../../fileSass/variables";

.formCustomRadioButton {
  margin-bottom: variables.$form-input-margin-bottom;
  @extend %displayColumn;

  .formElementLabel {
    margin-bottom: 6px;
  }

  &-container {
    @extend %displayWrapBetween;

    @include media.media479 {
      flex-direction: column;
    }
  }
  &-item {
    width: 48%;
    border: 1px solid variables.$border-blue;
    border-radius: 4px;
    padding: 12px 7px 11px 14.5px;
    display: flex;
    min-width: 200px;

    @include media.media479 {
      width: 100%;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .icon {
      margin-right: 30px;
      margin-top: 2px;
      svg {
        font-size: 16px;
      }
    }

    &-desc {
      @extend %displayColumn;
      span:nth-child(1) {
        font-weight: bold;
        font-size: 14px;
        color: variables.$fontColor-darkBlue;
        line-height: 19px;
      }
      span:nth-child(2) {
        font-size: 12px;
        line-height: 16px;
        color: variables.$fontColor-darkBlue;
      }
    }

    &--active {
      background-color: variables.$bg-blue;
      border: 1px solid variables.$border-darkBlue;

      svg {
        color: #fff;
      }

      .formCustomRadioButton-item-desc {
        span {
          color: #fff;
        }
      }
    }
  }
}
