@use "../../fileSass/media";
@use "../../fileSass/variables";

.tableCustom {
  position: relative;

  &-settingsMenu {
    display: none;

    &--multi {
      @include media.media1365 {
        display: block;
      }
    }
    &--more {
      transform: rotate(90deg);
      cursor: pointer;
    }

    &-menu {
      line-height: 0;
      border: none;

      .ant-menu-submenu {
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;
      }

      &-sub {
        border-radius: 15px;
        overflow: hidden;
        .ant-menu {
          &-item {
            font-size: 14px;

            &:hover {
              font-weight: 600;
              color: #000;
            }
            &-group-title {
              display: none !important;
            }
          }
        }
      }
    }
  }

  &-addButton {
    display: flex;
    // margin-left: 10px;

    .formSubmitButton {
      margin-left: 5px;
      // padding: 2px;

      .ant-addBtn {
        // padding: 0px;
        @include media.media767 {
          padding: 12px;
        }
      }
    }

    &--multi {
      @include media.media1365 {
        display: none;
      }
    }
  }

  &-addButtonSideBy {
    display: flex;
    margin-top: 11px;

    .formSubmitButton {
      margin-left: 0px;
    }

    &--multi {
      @include media.media1365 {
        display: none;
      }
    }
  }

  &-noBorder {
    td {
      border-top: solid 0 black !important;
      border-bottom: solid 0 black !important
    }
  }

  // &-multiSettingsActive {
  //   @include media1365 {
  //     display: flex;
  //   }
  // }

  &-filter {
    // overflow: hidden;
    // height: auto;
    // max-height: 800px;
    padding: 4px 4px;

    background-color: #f0f1f1;
    // border-radius: 4px;
    // margin-bottom: 1rem;

    @extend %slow400;


    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;

    // .ant-form{
    //   width: auto!important;
    // }

    .formSelectBox {
      max-width: 180px;
    }


    &-container {
      background-color: #f0f1f1;
      // display: flex;
      // padding-top: 4px;
      // padding-bottom: 4px;
      padding: 4px 6px;
    }

    &-close {
      display: none;
      max-height: 0;
      padding: 0 36px;
      @include media.media767 {
        padding: 0 15px;
      }
    }

    &-clear {
      width: 24px;
      margin-left: 4px;
    }


    &-form {
      display: flex;

      // flex-wrap: wrap;
      align-items: center;
      gap: 4px;
      // max-width: 640px;
      // justify-content: space-between;

      .formSelectBox-multiple .ant-select-selection-item {
        margin: 2px;
      }


      &-edit {
        border: 1px solid #264294;
        border-radius: 8px;
        padding: 4px 6px 4px 4px;
      }

      .formSelectBox:nth-child(2) {
        width: 164px;
      }

      .formSelectBox:nth-child(1) {
        width: 164px;
      }

      .formSelectBox__select{
        margin-top: 0;
      }

      .formTextInput__input{
        margin-top: 0;
      }

      .tagsFormElement-container{
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .formSelectBox,
      .formTextInput,
      .formTextArea,
      .formDateAndTimePicker,
      .formTreeSelect,
      .formDatePickerStartAndEnd,
      .formTimePickerStartAndEnd,
      .formSwitch,
      .formCustomCatalogsList,
      .formInputNumber,
      .formTextInputAndSelectBox,
      & > .formDatePicker {

        .ant-form-item {
          margin-top: 0;
          margin-bottom: 0;
        }

        .ant-form-item-with-help .ant-form-item-explain {
          min-height: 0;
          display: none;
        }

        // width: calc(25% - 20px);
        // margin: 0 1rem;
        // min-width: 120px;
        // max-width: 369px;
        // margin-bottom: 0;
        // margin: 0;
        // margin-right: 30px;
        @include media.media1920 {
          // max-width: 100%;
        }
        @include media.media1600 {
          // width: calc(33.3333% - 20px);
        }
        @include media.media1200 {
          // width: 48%;
          // margin: 0;
        }
        @include media.media719 {
          // width: 100%;
        }
      }

    }
  }

  &-doubleRow {
    @extend %displayCenter;
    > div:first-child {
      margin-right: 10px;
    }
  }
  &__editButton,
  &__deleteButton,
  &__createWoButton {
    cursor: pointer;
    color: variables.$ebaq-gray6;

    padding: 4px;
    border-radius: 4px;
    svg {
      font-size: 1.2rem;
    }

    @extend %slow300;
    &:hover {
      color: variables.$fontColor-orange;
    }
  }
  &__fileButton {
    cursor: pointer;
    color: variables.$ebaq-gray6;
    @extend %slow300;
    &:hover {
      color: variables.$fontColor-orange;
    }
  }
  &__deleteButton {
    transform: rotate(270deg);
  }
  &__fileButton {
    margin-left: 25px;
  }

  &-header {
    @extend %displayBetweenCenter;
    width: 100%;
    padding: 8px;
    // padding-right: 12px;
    // padding-top: 12px;
    // padding: 1rem 0;

    @include media.media767 {
      padding: 12px 4px 12px;
    }
    @include media.media479 {
      align-items: flex-start;
    }
    &-search {
      flex: 1;
      gap: 6px;

      @extend %displayCenter;
      @include media.media479 {
        // flex-direction: column;
        align-items: center;

      }

      &--point {
        &:after {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: variables.$ebaq-orange;
          position: absolute;
          bottom: -1px;
          right: -1px;
          z-index: 5;
        }
      }






      &__antd {
        flex: 1;
        max-width: 380px;
        gap: 6px;
        // width: 395px;
        height: 32px;
        border: 1px solid rgba(38, 66, 148, 0.15);
        border-radius: 16px;
        // margin-left: 10px;
        padding: 0 5px;

        @include media.media767 {
          // width: 200px;
          margin-left: 0;
          max-width: 240px;
        }
        @include media.media479 {
          margin-left: 0;
          // margin-top: 10px;
          // width: 160px;
        }

        .ant-input-search {
          margin-top: 1px;
          border-radius: 20px;
          overflow: hidden;
          input {
            //color: #1c1c38;
            font-size: 12.5px;
            font-weight: 600;
            border: none !important;
            box-shadow: none;
            border-color: transparent;
            width: calc(100% - 30px);
            margin-right: 2px;
            outline: none;

          }
        }

        .ant-input-group-addon {
          top: -2px;
          height: 10px !important;
          display: block;
          right: -15px !important;

          button {
            border: none;
            border-left: 1px solid variables.$fontColor-orange !important;

            &:hover {
              border-left: 1px solid variables.$fontColor-orange !important;
            }

            svg {
              color: #1c1c38;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .ant-table-cell,
  .ant-table-cell > span,
  .ant-table-column-sorters > span {
    // font-weight: 600;
    font-size: variables.$fontSize-primary;
    // line-height: 18px;
    color: variables.$fontColor-primary;
    // padding: 8px;
    padding: 4px;

    .pausedIcon {
      font-size: 18px;
      color: variables.$error-red;
      padding:0;

    }

  }

  .ant-table-thead{
    th{
      font-weight: 600;
      font-size: 10px;
      svg {
        font-size: 9px;
      }
      .ant-table-column-sorters > span {
        font-size: 10px;
      }
    }
  }

  .ant-radio-inner::after {
    background-color: variables.$bg-primary;
  }

  .ant-radio-checked .ant-radio-inner {
    border: 1px solid variables.$bg-primary;
  }

  .ant-pagination-item {
    display:inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-total-text {
    font-size:10px;
    color:gray;
    font-style: italic;
  }

  .ant-table-content {
    @extend %scrollXBlue;

    line-height: 1.15;
  }

  // body iç
  .ant-table-tbody {
    tr {
      &:hover {
        .ant-table-cell,
        .ant-table-cell > span {
          color: variables.$fontColor-blue;
        }

        .ant-radio-inner {
          border-color: variables.$bg-primary;
        }
      }
      .ant-table-cell {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        .ant-table-cell {
          border-bottom: none;
        }
      }
    }
    .ant-table-cell {
      padding: 0.25rem;
      border: none;
      height: 3.3rem;
    }
  }

  // .ant-table-selection-column {
  //   padding-left: 36px !important;
  //   padding-right: 11px !important;
  // }

  .ant-pagination-options {
    //display: none !important;
    padding-right: 4px;
    .ant-select-selector {
      min-width:96px;
    }
  }


}

.tableCustom_button_noSearch {
  justify-content: flex-end;
}

.tableCustom_pausedIcon {

  span{
    color: red;
    font-size: 22px;
  }
}


.equalizer-line {
  transform: rotate(-90deg);
  cursor: pointer;
  svg {

    color: #1c1c38;
  }

  &-active {
    transform: rotate(-90deg);
    cursor: pointer;
    svg {
      color: variables.$ebaq-orange;
    }

  }

}

.withBorder {
  border: 1px solid variables.$ebaq-gray6;
  @extend %slow300;
  &:hover {
    border: 1px solid variables.$fontColor-orange;
  }

}