.none-priority {
  border-top: 2px solid #f9f9f9 !important;
}
.low-priority {
  border-top: 2px solid rgb(255, 214, 49) !important;
}
.medium-priority {
  border-top: 2px solid rgb(255, 124, 51) !important;
}
.high-priority {
  border-top: 2px solid rgb(245, 35, 35) !important;
}

.workOrderListItem {
  border: 1px solid rgba(73, 97, 153, 0.2);
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 12px;
  // margin-top: 12px;



  &-header {
    display: flex;
    align-items: center;
    // flex-direction: column;
    height: 22px;

    &-title {
      flex: 1;
      font-weight: 500;
      font-size: 13px;
      color: rgb(73, 97, 153);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-priority {
      display: flex;
      justify-content: flex-end;
      width: 100px;
    }
  }

  &-sub-header {
    display: flex;
    align-items: center;
    justify-content:space-between;
    border-top: 1px solid rgba(73, 97, 153, 0.1);;
    margin-top: 4px;
    padding-top: 4px;

    &-status {
      width: 100px;
    }
    &-target-date {

      // font-size: 13px;
    }
  }

  &-resource {
    margin-top: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }

  &-desc {
    padding-top: 4px;
  }

}
