@use "../../fileSass/variables";
@use "sass:math";


.formSelectBox {
  width: 100%;
  .ant-form-item-control-input {
    min-height: 0;
  }
  .ant-select-selection-placeholder {
    line-height: variables.$form-height !important;
    font-weight: normal;
  }
  &__select {
    // margin-top: 6px;
    border-radius: 4px;
    background-color: variables.$form-bg !important;
    color: variables.$form-fontColor;
    line-height: variables.$form-height;
    font-size: variables.$form-fontSize;
    height: variables.$form-height;
    // line-height: 1.44;
    // font-weight: 500;
    // @extend %slow400;
    // font-size: variables.$form-fontSize;

    &.ant-select-focused {
      .ant-select-selector {
        border: solid 1px variables.$form-border-focus !important;
      }
    }

    &:hover {
      .ant-select-selector {
        border: solid 1px variables.$form-border-focus !important;
      }
    }

    .ant-select {
      &-item-option-disabled {
        color:red;

      }
      &-arrow {
        right: 12px;
        // dart sass implementation
        top: math.div(variables.$form-height ,2) + 1;
        svg {
          font-size: 11px;
        }
      }

      &-selection {
        &-search {
          padding-top: 2px;
          &-input {
            height: 92% !important;
            font-size: variables.$form-fontSize;
            line-height: variables.$form-height;
            border-right: 1px solid #ff9212 !important;
            width: calc(100% - 8px) !important;
          }
        }

        &-placeholder {
          font-size: variables.$form-fontSize;
          display: block;
          padding-left: 12px !important;
        }
        &-item {
          padding: 4px 12px !important;
          @extend %displayCenter;
          font-weight: normal;
          color: variables.$form-fontColor;

          font-size: variables.$form-fontSize;
        }
      }
      &-selector {
        padding: 0 11px 0 0 !important;
        border-radius: 4px !important;
        border: solid 1px variables.$form-border !important;
        outline: none;
        box-shadow: none !important;
        background-color: variables.$form-bg !important;
        // min-height: 34px !important;
      }
    }
    &.ant-select-disabled {
      .ant-select-selector {
        background: #f7f6f6 !important;
      }
      &:hover {
        .ant-select-selector {
          background: #fff !important;
        }
      }
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .ant-select-selector {
    min-height: variables.$form-height;
  }

  .formTextInput {
    width: 100% !important;
  }

  &-multiple {
    .ant-select-selection {
      &-placeholder {
        padding-left: 0 !important;
      }
      &-item {
        margin: 4px;
        border: 1px solid variables.$form-border;
        border-radius: 4px;
        display: flex;
        max-width: 100%;

        &-content {
          color: variables.$form-fontColor;
          // letter-spacing: 0.3px;
          font-size: 11px;
          overflow:visible;
        }
      }
    }

    svg {
      font-size: 11px;
    }
  }

  .ant-select-clear {
    width: 15px;
    height: 15px;
    z-index: 100;

    svg {
      font-size: 13px;
    }
  }
  //other altındaki boşluğu alıyor
  .ant-row:nth-child(3) {
    margin-bottom: 0;
  }

  // .ant-select-arrow {
  //   color: #000;
  // }

  .ant-form-item {
    margin-bottom: variables.$form-input-margin-bottom;
  }
}
