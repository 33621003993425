@use "../../fileSass/variables";

.formRadioGroup {
  width: 100%;

  &__radioGroup {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-radio-wrapper {
      line-height: 1.44;
      font-size: variables.$form-fontSize;
      white-space: normal;
      margin: 8px;
      @extend %displayCenter;
      span {
        color: variables.$form-fontColor;
      }
      .ant-radio {
        height: 18px;
      }
    }
  }

  .formTextInput-container__label {
    display: none;
  }
}
