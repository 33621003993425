@use "../../fileSass/variables";

.formTimePicker {
  width: 128px;
  .ant-picker-focused,
  .ant-picker:hover {
    box-shadow: none;
  }

  &__timePicker {
    // margin-top: 6px;
    height: variables.$form-height;
    border-radius: 4px;
    border: solid 1px variables.$form-border;
    background-color: variables.$form-bg;
    padding: 0 12px;
    color: variables.$form-fontColor;
    line-height: variables.$form-height;
    font-size: variables.$form-fontSize;
    // min-height: 34px !important;
    &:focus {
      border: solid 1px variables.$form-border-focus;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    }

    &:hover {
      border: solid 1px variables.$form-border-focus;
    }

    input {
      color: variables.$form-fontColor;
      line-height: variables.$form-height;
      font-weight: 400;
      font-size: variables.$form-fontSize;

    }

  }
}
