@use "../../fileSass/variables";

.formMask {
  width: 100%;

  &__input {
    // margin-top: 6px;
    height: variables.$form-height;
    border-radius: 4px;
    border: solid 1px variables.$form-border;
    background-color: variables.$form-bg;
    padding: 0 12px;
    color: variables.$form-fontColor;
    line-height: variables.$form-height;
    font-size: variables.$form-fontSize;
    outline: none;
    width: 100%;
    &:focus {
      border: solid 1px variables.$form-border-focus;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    }

    &:hover {
      border: solid 1px variables.$form-border-focus;
    }
  }

  .ant-form-item-has-error {
    input {
      border-color: variables.$error-red;
      outline: none;

      &:focus {
        border: solid 1px variables.$error-red;
      }

      &:hover {
        border: solid 1px variables.$error-red;
      }
    }
  }
}
