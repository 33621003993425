@use "../../fileSass/media";
@use "../../fileSass/variables";

.titleHeader {
  font-size: 13px;
  font-weight: 700;
  color: variables.$fontColor-gray;
  white-space: nowrap;
  position: absolute;
  top: -48px;
  left: 0;
  height: 48px;
  line-height: 48px;
  padding-left: 8px;
  font-family: "Montserrat", "Open sans", serif;
  @include media.media1023 {
    left: 34px;
  }
}
