$muiTypography-font-family: Open Sans, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, 'Montserrat';

$muiTypography-font-size: 12px;
//MuiMenu-paper MuiPopover-paper MuiPaper-elevation2 MuiPaper-rounded
.MuiPaper {

  &-root {
    z-index: 9999999!important;


  }
}


.MuiListItem-gutters {
  padding-left: 12px!important;
  padding-right: 12px!important;
}



.MuiPopover {
  &-root {
    z-index: 9999999;
    .chonky {
      &-icon{
        font-size:12px!important;
      }
    }
  }

}

.MuiTypography {

  &-body {
    font-family: $muiTypography-font-family;
    font-size: $muiTypography-font-size!important;
  }

  &-body1 {
    font-family: $muiTypography-font-family;
    font-size: $muiTypography-font-size!important;
  }

  /*
  .chonky{
    &-text {
      font-size: 10px!important;
    }
    &-icon {
      font-size: $muiTypography-font-size!important;
    }

  }

   */



}

// 16px


// Set styles on all <div> elements that have a class attribute value that begins with "listFileEntryProperty":
div[class^="listFileEntryProperty"] {
  font-size: $muiTypography-font-size!important;
}

div[class^="selectionIndicator"] {
  background: #1890ff;
}

div[class^="focusIndicator"] {
  box-shadow: rgb(24, 144, 255) 0 0 0 1.5px inset;
  border-radius: 4px;
}

.MuiOutlinedInput{
  &-root {
    font-size: 8px !important;
    height: 32px;
    // border: 1px solid rgba(38, 66, 148, 0.15)!important;
    border: none !important;
    border-radius: 16px!important;
  }
}

.resourceFileBrowser {
  flex-grow: 1;
  display:flex;
  min-height: 400px;



}


.chonky {
  font-family: $muiTypography-font-family;
  font-size: $muiTypography-font-size!important;
  border: 0;

  &-baseButton {
    font-size: $muiTypography-font-size!important;
    min-height:18px!important;
    span {
      font-size: $muiTypography-font-size !important;
    }

  }

  &-text {
    font-size: 12px !important;
    span {
      font-size: $muiTypography-font-size !important;
    }
  }

  &-infoText {
    font-size: 12px!important;
    p{
      font-size: $muiTypography-font-size !important;
    }
  }

  &-infoContainer {
    font-size: 12px!important;
    p{
      // font-size: $muiTypography-font-size !important;
      font-size: 13px !important;
    }
  }



  &-extraInfoSpan {
    font-size: 10px!important;
  }

  &-selectionSizeText {
    font-size: 10px!important;
  }

  &-searchFieldInput {
    height: 32px !important;
    // border: 1px solid rgba(38, 66, 148, 0.15)!important;
    // border-radius: 16px;

    // input {
    //       //color: #1c1c38;
    //       font-size: 12.5px;
    //       font-weight: 600;
    //       border: none !important;
    //       box-shadow: none;
    //       border-color: transparent;
    //       width: calc(100% - 30px);
    //       margin-right: 2px;
    //       outline: none;
    //
    //     }
  }

  &-searchFieldInputInner {
    // height: 32px !important;
    // border-radius: 16px;
    // border: 1px solid rgba(38, 66, 148, 0.15);
    color: rgba(0, 0, 0, 0.85);
    font-size: 12.5px!important;
    font-weight: 600;

    font-family: Open Sans, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, "Montserrat";;
    input {

      border: none !important;
      box-shadow: none;
      border-color: transparent;
      width: calc(100% - 30px);
      // margin-right: 2px;
      outline: none;
    }
  }


  &-icon {
    font-size: $muiTypography-font-size !important;
  }



  // &-browserMenuTooltip {
  //   display: none;
  // }

  // &-navbarWrapper {
  //   display: none;
  // }

  &-chonkyRoot {
    font-family: Open Sans, sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, 'Montserrat';
    font-size: $muiTypography-font-size!important;
    border: 0;

    // ** default values **
    //
    // color: rgba(0, 0, 0, 0.87);
    // border: solid 1px rgba(0, 0, 0, 0.12);
    // height: 100%;
    // display: flex;
    // padding: 8px;
    // font-size: 15px;
    // box-sizing: border-box;
    // text-align: left;
    // font-family: sans-serif;
    // user-select: none;
    // touch-action: manipulation;
    // border-radius: 4px;
    // flex-direction: column;
    // -ms-user-select: none;
    // moz-user-select: none;
    // background-color: #fff;


  }

}






