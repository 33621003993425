@use "variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
input,
tr {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  // line-height: 1;
  font-family: variables.$fontFamily-primary;
  font-size: variables.$fontSize-primary;
  // color: variables.$fontColor-primary;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  &:hover {
    color: variables.$fontColor-primary;
  }
}

#root {
  min-height: 100vh;
  width: 100%;
}

// auto seçince arka plan rengini ayarlama

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid $font-white;
  // -webkit-text-fill-color: $font-white;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
