@use "../../fileSass/media";

.dataNotFound {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @extend %displayCenterCenter;
  margin-top: 15px;
  .icon {
    margin-right: 13px;
  }

  &__title {
    font-size: 22px;
    font-weight: 500;
    white-space: nowrap;
    @include media.media1365 {
      font-size: 18px;
    }
    @include media.media767 {
      font-size: 16px;
    }
  }
}
