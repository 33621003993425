@use "../../fileSass/variables";

.formInputNumber {
  width: 100%;

  .ant-input-number-focused {
    border: solid 1px variables.$form-border-focus;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
  }
  &__input {
    width: 100%;
    // margin-top: 6px;
    border-radius: 4px;
    border: solid 1px variables.$form-border;

    input {
      background-color: variables.$form-bg;
      // height: variables.$form-height;
      color: variables.$form-fontColor;
      // line-height: variables.$form-height;
      font-size: variables.$form-fontSize;
      outline: none !important;
    }

    &:hover {
      border: solid 1px variables.$form-border-focus;
    }
  }

  .ant-input-number-disabled {
    background-color: #f5f5f5;
  }

  .ant-input-number-disabled .ant-input-number-input {
    cursor: pointer;
    background-color: #f5f5f5 !important;
  }

}

.withoutNumberHandler {
  .ant-input-number-handler-wrap {
    display: none;
    width: 0;
  }
}
