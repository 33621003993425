@use "../../fileSass/media";

.formDateAndTimePicker {
  .ant-picker {
    width: 100% !important;
  }

  &-container {
    @extend %displayBetween;

    .formDatePicker {
      width: calc(100% - 148px);
      max-width: 100%;
      @include media.media767 {
        width: calc(50% - 10px);
      }
    }

    .formDatePicker-container__datePicker {
      width: 100%;
    }

    .formTimePicker {
      margin-left: 20px;
      min-width: 128px;

      @include media.media767 {
        min-width: calc(50% - 10px);
        width: calc(50% - 10px);
      }
    }
  }
}
