@use "../../fileSass/media";

.pageNotFound {
  &-logo {
    height: 100px;
    width: 160px;
    padding: 0 10px;
    @extend %displayCenter;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-text {
    @extend %displayCenterCenter;
    min-height: calc(80vh - 100px);

    &-container {
      @extend %displayColumn;
      &__title {
        font-size: 30px;
        font-weight: bold;

        margin-bottom: 18px;
        @include media.media1023 {
          font-size: 28px;
        }
        @include media.media767 {
          font-size: 26px;
        }
        @include media.media479 {
          font-size: 24px;
        }
      }
      &__description {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.58;
        margin-bottom: 12px;
        @include media.media1023 {
          font-size: 24px;
        }
        @include media.media767 {
          font-size: 22px;
        }
        @include media.media479 {
          font-size: 20px;
        }
      }
      &__errCode {
        font-size: 66px;
        font-weight: 600;
        margin-bottom: 26px;
        @include media.media1023 {
          font-size: 60px;
        }
        @include media.media767 {
          font-size: 55px;
        }
        @include media.media479 {
          font-size: 50px;
        }
      }

      &__button {
        width: 170px;
        height: 38px;
      }
    }
  }
}
