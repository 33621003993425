.drawer-title {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-actions {
    justify-content: flex-end;
    .ant-tag{
      font-size: 10px;
      line-height: 16px;
    }

    svg {
      font-size: 16px;
      color: #BDBDBD;
      //color: #ffc48d;
    }

  }

}
