@use "../../fileSass/variables";

.resourceInfoCard {
  margin-bottom: 12px;
  border: 1px solid variables.$ebaq-blue;

  .ant-card-body{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px;
  }

  &--leftIcon {
    width: 32px;
    svg {
      font-size: 32px;
      color: variables.$ebaq-blue;
    }
  }

  &--infoContainer {
    flex:1;
    display: flex;
    justify-content: flex-start;

    &--info {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .resource-name{
        color: variables.$ebaq-blue;
        font-size: 13px;
        margin-bottom: 4px;
        // text-align: right;
        font-weight: 600;
      }

    }
  }



}
