@use "../../fileSass/variables";

.issueCard {
  margin-bottom: 12px;
  border: 1px solid #ff86866b;
  border-radius: 4px;
  background: rgb(255, 248, 248);

  .ant-card-body{
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 12px;
  }

  &--leftIcon {
    width: 24px;
    svg {
      font-size: 24px;
      color: variables.$issue-color;
    }
  }

  &--infoContainer {
    flex:1;
    display: flex;
    justify-content: flex-start;

    &--info {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .issue-subject{
        color: variables.$issue-color;
        font-size: 13px;
        margin-bottom: 4px;
        // text-align: right;
        font-weight: 600;
      }
      .issue-desc{
        color: #757575;
        font-size: 12px;
      }

      .issue-files-container{
        margin-top: 12px;
      }
    }
  }



}
