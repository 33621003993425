.alertMessage {
  width: 100%;
  margin: 20px 0;
  padding: 10px 15px 5px !important;

  .ant-alert-message {
    display: none;
  }
  &-list {
    @extend %displayColumn;
    &__card {
      line-height: 1.2;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}
