@use "../../../styles/fileSass/variables";

%titleContainer {
  font-weight: 600;
  font-size: 12px;
  // line-height: 20px;
  margin-bottom: 2px;
}

.title {

  &-gray {

    color: #828282;
  }
  &-light-gray {
    @extend %titleContainer;
    color: #a9a9a9;
  }
  &-blue {
    @extend %titleContainer;
    color: variables.$ebaq-blue;
  }
  &-light-blue {
    @extend %titleContainer;
    color: variables.$ebaq-light-blue;
  }
  &-yellow {
    @extend %titleContainer;
    color: #ecb300;
  }
  &-red {
    @extend %titleContainer;
    color: variables.$error-red;
  }
}
