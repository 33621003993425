@use "../../fileSass/media";

.drawerModal {
  z-index: 999;

  .formResponsive-btn {
    padding-top: 15px;
  }

  &__line {
    width: 100%; // calc(100% + 56px);
    height: 0.5px;
    background-color: rgba(38, 66, 148, 0.2);
    margin-left: -28px;
    margin-bottom: 12px;
  }

  .ant-drawer {
    &-body {
      padding: 16px; // 28px 90px;
      @extend %scrollYGray;
      height: 100vh;

      @include media.media767 {
        padding: 16px 20px 50px;
      }
    }
    &-content {
      &-wrapper {
        width: 440px !important;

        @include media.media767 {
          width: 420px !important;
        }
        @include media.media479 {
          width: 360px !important;
        }
        @include media.media359 {
          width: 300px !important;
        }
      }
    }

    &-header {
      padding: 12px 18px;
      // padding: 2px 0;
      svg {
        color: #fff;
      }

      &-title {
        width: 100%;
      }
    }
    &-close {
      // padding: 14px 18px;
      padding:0;
      margin: 0;
      order: 2;
      display: flex;
      align-items: center;
    }

    &-title {
      font-weight: 600;
      font-family: "Montserrat", Open sans;
      font-size: 14px;
      color: white;

      @include media.media767 {
        font-size: 14px;
      }

    }
  }
}
