.entryOnHoldRecordCard {
  margin-bottom: 12px;
  border: 1px solid #ffc107;
  border-radius: 4px;
  background-color: #FCFCFC;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 100%;
    // padding: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .ant-divider {
    margin: 0 0;
    border-top: 1px solid #E0E0E0;
  }

  &--itemContainer {
    display: flex;
    flex-direction: column;
    padding-top: 8px;

    &--createdByContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .created-at{
        font-size:9px;
      }
      span{
        font-size:10px;
        color: #7c7c7c;
      }
    }
    &--desc {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    &--resolve {
      display: flex;
      justify-content: end;
      align-items:center;
      width: 100%;
      padding-bottom: 4px;
      padding-top: 4px;

      .ant-btn[disabled] {
        background: #98d298;
      }

      .resolve-button-sm {
        margin-top: 8px;
        margin-bottom: 8px;
        color: #fff;
        font-size:12px;
        background-color: #00a100;
        span {
          color: #fff;
          font-size: 11px;
        }
      }

    }
  }


}

.resolved-by-container {
  padding:4px 7px;
  background: #ecffec;
  border-radius: 4px;
  border: 1px solid #c3f1c3;
  display: flex;
  justify-content: end;
  align-items:center;
  span {
    color: #00a100;
    font-size:11px;
  }
}
