.tagCustom {
  /*
  padding: 0.35rem;
  min-width: 140px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  //font-weight: 600;
  font-size: 14px !important;
  line-height: 1rem;

   */
  /*
  padding: 0.35rem;
  min-width: 140px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  font-size: 14px !important;
  line-height: 1rem;
   */
  //
  padding: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
  // font-size: 10px!important;
  // font-weight: 600;
  // line-height: 1rem;

  &-text {
    font-size:10px;
    font-weight: 600;
    // padding: 2px 6px;
  }

}

.withIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}
