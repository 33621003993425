@use "../../fileSass/media";
@use "../../fileSass/variables";

.breadCrumb {
  @extend %displayCenter;
  // padding: 1rem 0;
  // padding-top: 6px;
  // padding-bottom: 6px;
  // border-bottom: 1px solid $bg-gray;
  background: variables.$bg-gray;
  @include media.media1023 {
    padding: 10px 35px;
  }
  @include media.media767 {
    padding: 10px 25px;
  }
  @include media.media479 {
    flex-wrap: wrap;
    padding: 8px 0 4px 0;
  }
  &-item {
    @extend %displayCenter;
    @include media.media479 {
      flex-wrap: wrap;
      margin-bottom: 5px;
    }
    &__link {
      @extend %slow400;
      // font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #c0c0c0;
      &:hover {
        color: #949494;
      }
      &.last {
        pointer-events: none;
        font-weight: 600;
        color: variables.$fontColor-orange;
        &:hover {
          color: variables.$fontColor-orange;
        }
      }
    }
    &__arrow {
      margin: 0 5px;
      color: rgba(39, 99, 143, 0.3);
      .iconify {
        font-size: 1.25rem;
      }
    }
  }
}
