
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  &--innerPage {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    @extend %displayCenterCenter;
    // @extend ex, %displayCenterCenter;
  }

  .ant-spin {
    line-height: 1;
  }
}
