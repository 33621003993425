@use "../../../../Assets/styles/fileSass/variables";

.identityProfile {

  display: flex;
  flex-direction: column;
  gap: 12px;

  &-close {
    display: flex;
    justify-content: flex-end;
    &-icon {
      font-size: 18px;
      cursor: pointer;
    }
  }

  &-avatar {
    display: flex;
    justify-content:center;
    margin-top: 24px;

    .identityAvatar {
        .ant-avatar-circle {
          span {
            font-size: 28px;
            font-weight: 600;
          }
        }
      }
  }

  &-header {
    display: flex;
    align-items: center;
    flex-direction: column;

    flex:1;

    &-edit {
      padding-top:10px;
      cursor: pointer;
      span {
        color: variables.$ebaq-orange;
        font-size: 10px;
        font-weight:600;
      }
    }

    &-edit-btn {
      // position: absolute;
      // right: 20px;
      // left:auto;
      // top: 10px;
      align-self: flex-end;
    }

    &-title {
      font-size:15px;
      display: flex;
      align-items: center;
      gap:5px;
    }

    &-subtitle {
      padding-top:6px;
    }


    &-contact-info {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top:10px;

      a {
        font-size:11px;
      }
    }

  }
}
