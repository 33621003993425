.formHtmlEditor {
  &__input {
    .ql-toolbar {
      border-radius: 5px 5px 0 0;
      border: 1px solid #e0e0e0 !important;
      border-bottom-style: none !important;
    }
    .ql-container {
      border: 1px solid #e0e0e0 !important;
      border-radius: 0 0 5px 5px;
    }
    .ql-editor {
      @extend %scrollYBlue;
      max-height: 300px;
      min-height: 120px;
      background-color: #fff;
    }
    .ql-formats {
      margin-right: 0 !important}
  }
  .ant-form-item-has-error {
    .ql-container {
      border-color: #ff4d4f !important;
    }
  }
}
