@use "../../fileSass/media";
@use "../../fileSass/variables";


.buttonPageTab {
  // @extend %displayJustCenter;
  // display: flex;
  // justify-content: center;
  border-bottom: 1px solid variables.$bg-lightBlue;
  // border-top: 1px solid variables.$bg-lightBlue;
  background: #f8faff;
  overflow-x: auto;

  &-item {
    @extend %displayCenter;
    border-radius: 6px;
    padding: 8px 16px 8px 16px;
    background: transparent;
    border: 1px solid variables.$bg-lightBlue;

    // @extend %slow400;
    .iconify {
      // @extend %slow400;
      color: variables.$bg-darkBlue;
      font-size: 14px;
      margin-right: 6px;
    }
    > span {
      // @extend %slow400;
      color: variables.$bg-darkBlue;
      // font-family: "Montserrat", "Open sans", serif;
      font-family: "Montserrat", "Open sans", serif;
      font-weight: 600;
      font-size: 11px;
    }
  }

  /*
  .ant-tabs-nav-list{
      padding-left: 4px;
    }
   */
  .ant-tabs {
    &-ink {
      &-bar {
        height: 0 !important;
      }
    }
    &-nav {
      margin-bottom: 0px;
      &-operations {
        // ant-tabs-nav-more
        background: rgb(248 250 255);
        z-index: 2;
      }
      &-list {
        padding-left: 4px;
      }
      &::before {
        border-bottom: none !important;
      }
    }
    &-tab {
      @include media.media1023 {
        padding: 5px 0;
        margin: 0 6px 0 0;
      }
      @include media.media767 {
        padding: 4px 0;
        margin: 0 6px 0 0;
      }
      @include media.media479 {
        padding: 4px 0;
        margin: 0 6px 0 0;
      }
      .iconify,
      span {
        color: variables.$bg-darkBlue;
      }

      &-active {
        .buttonPageTab-item {
          // background: variables.$ebaq-orange;
          background: #fe9635;
          // border: 1px solid variables.$ebaq-orange-dark;
          border: 1px solid #eb8425;
        }
        .iconify,
        span {
          color: #753f0f;
          // font-weight: 600;
        }
      }
    }
  }
}

.buttonPageTabWhite {

  border-bottom: 0;
  background: #dfe8ff;

  .buttonPageTab-item {
    padding: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;


    .iconify {
      @extend %slow400;
      font-size: 15px;
      margin-right: 6px;
      // color: variables.$ebaq-light-blue  ;
      color: #5f80d3;
    }
    > span {
      // color: variables.$ebaq-light-blue;
      color: #5f80d3;
      font-weight: 600;
    }



  }



  .active {

    span {
      color: variables.$ebaq-orange;
    }

    background: white;
    border: 1px solid white;

    .icon{
      color: variables.$ebaq-orange;
      svg {
        color: variables.$ebaq-orange;
      }
    }


  }

  .ant-tabs {
    &-ink {
      &-bar {
        height: 0 !important;
      }
    }
    &-nav {
      &-list {
        margin-left: 0 !important;
      }

      &-operations {
        background: #dfe8ff!important;
      }

    }
    &-tab {
      margin: 0 5px 0 0 !important;
      padding: 0 !important;

      &-active {
        .buttonPageTab-item {
          background: #fff;
          border: 1px solid white;
        }

        span {
          color: #4f4f4f;
        }
      }
    }
  }
}
