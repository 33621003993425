%baseWorkOrder {
    min-width: 80px;
}

%baseWorkOrderTagText {
    // font-size: 10px;
}

.workorders {

    &-open {
        @extend %baseWorkOrder;
        background-color: #D3FFE8;
        border: 0.5px solid #0CC863;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #0a8342;
        }
    }

    &-progress {
        @extend %baseWorkOrder;
        background-color: #b9daff;
        border: 0.5px solid #264294;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #264294;
        }
    }

    &-complete {
        @extend %baseWorkOrder;
        background-color: #F5F5F5;
        border: 0.5px solid #0CC863;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #0CC863;
        }
    }

    &-wait {
        @extend %baseWorkOrder;
        background: #ffc107;
        border: 0.5px solid #f6ba06;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #000;
        }
    }

    &-cancel {
        @extend %baseWorkOrder;
        background: #ececec;
        border: 0.5px solid #afafaf;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #8c8c8c;
        }
    }

    &-check-consultant {
        @extend %baseWorkOrder;
        background: #E3E3FA;
        border: 0.5px solid #4A4AFF;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #4A4AFF;
        }
    }

    &-sub-orders {
        @extend %baseWorkOrder;
        background: #FFFAE3;
        border: 0.5px solid #FFCC00;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #FFCC00;
        }
    }

    &-consultant-reject {
        @extend %baseWorkOrder;
        background: #FBDBDB;
        border: 0.5px solid #E50F0F;
        border-radius: 16px;

        &-text {
            @extend %baseWorkOrderTagText;
            color: #E50F0F;
        }
    }
}

%basePriority {
    min-width: 60px;
}
.workOrderPriority {
    &-none {
        @extend %basePriority;
        background-color: #d9d9d926;
        border: 0.5px solid rgba(217, 217, 217, 0.37);

        &-text {
            color: #c0bebe;
        }
    }

    &-low {
        @extend %basePriority;
        background-color: rgb(255, 214, 49);
        border: 0.5px solid rgb(131, 118, 65);

        &-text {
            // color: #ffffff;
            color: rgb(131, 118, 65);

        }
    }

    &-medium {
        @extend %basePriority;
        background-color: rgb(255, 124, 51);
        border: 0.5px solid rgb(143, 84, 52);

        &-text {
            color: #ffffff;
        }
    }

    &-high {
        @extend %basePriority;
        background: rgb(245, 35, 35);
        border: 0.5px solid rgb(143, 35, 35);

        &-text {
            color: #ffffff;
        }
    }

}
