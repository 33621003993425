.formLogin {
  @extend %displayWrapBetween;

  .alertMessage {
    margin: 0 0 30px;
    padding: 15px 15px !important;
  }

  .formElementLabel {
    // color: #696f79;
    /// font-weight: bold;
    // font-size: 16px;
    line-height: 22px;
    margin-left: 2px;
    // margin-bottom: 5px;
  }

  .formTextInput,
  .formMask {
    .formMask__input,
    .formTextInput__input {
      // height: 48px;
      // line-height: 19px;
      // border: 1px solid #264294;
      margin-top: 0;
    }

    input {
      color: #696f79;
      // font-size: 14px;
      // font-weight: 600;
    }

    .formMask__input::placeholder {
      color: #e0e0e0;
    }
  }

  .ant-form-item-explain {
    // padding-left: 23px;
  }

  .formCheckBox {
    width: 100%;
  }
  .ant-checkbox-inner {
    background-color: transparent !important;
  }
  .formCheckBox__label {
    @extend %displayCenter;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner::after {
      border-color: #ff9212;
    }
  }
}
