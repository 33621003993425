@use "../../fileSass/media.scss";
@use "../../fileSass/variables.scss";
@use "../../fileSass/extend.scss";

.confirmModal {
  &-container {
    @extend %displayColumn;
  }

  .ant-modal-body {
    padding: 24px 24px 20px;
    media1023 {
      padding: 20px 20px 16px;
    }
  }

  .ant-modal-confirm-title {
    margin-bottom: 15px;
    font-weight: 600;
  }

  .ant-btn {
    border-radius: 4px;
    border: none;
    background: #4caf50;
    &:hover {
      background: #338d36;
    }
    span {
      color: #fff;
    }
  }

  &--success {
    .ant-modal-confirm-title {
      color: variables.$success-green;
    }
  }

  &--warning {
    .ant-modal-confirm-title {
      color: variables.$warning-yellow;
    }
    .ant-btn:nth-child(1) {
      background: #dd3c30;
      &:hover {
        background: #c42e24;
      }
    }
  }

  &--error {
    .ant-modal-confirm-title {
      color: variables.$error-red;
    }
  }

  &--info {
    .ant-modal-confirm-title {
      color: variables.$info-blue;
    }
  }

  &--confirm {
    .ant-modal-confirm-title {
      color: #000;
    }
    .ant-btn:nth-child(2) {
      background: #dd3c30;
      &:hover {
        background: #c42e24;
      }
    }
  }
}
