@use "../../../../Assets/styles/fileSass/variables";

.work-order-detail-multi-drawer {
  &-form-preview {
    .ant-drawer-body {
      //background: #eeeeee;
      background: variables.$bg-gray;
    }
  }

}


.multiDrawerNoTitle {

  .ant-drawer-header {
    display: none;
  }

  .ant-divider-horizontal {
    margin: 12px 0;
  }
}

.multiDrawerWithTitle {

  .ant-drawer-body {
    display: block!important;
  }
}


.woMultiDrawerNoTitle {

  .ant-drawer-header {
    display: none;
  }

  .ant-divider-horizontal {
    margin: 12px 0;
  }

  .ant-drawer-content {
    background: rgb(241, 242, 255);
  }

  .inspection-card-container {
    background: rgb(248, 249, 255);
  }

  .formMultiUploadFile-container {
    background: rgb(241, 242, 255);

    .formMultiUploadFile-container-btn {
      background: rgb(241, 242, 255);
    }
  }


}
