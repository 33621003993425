@use "variables";
@use "media";


.ant-form {
  width: 100%;
}

.ant-empty-image {
  height: 4rem;
  margin-bottom: 8px;
}

.ant-empty-description{
  font-size: 12px;
}


//FormSelectBox için yapıldı
.ant-select-dropdown {
  padding: 10px 5px 10px 5px !important;
  z-index: 99999;
  .rc-virtual-list-holder {
    @extend %scrollYBlue;
  }
  //ant scroll iptal ediyor.
  .rc-virtual-list-scrollbar {
    display: none;
  }

  border-radius: 6px;
  box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 0;
}

.ant-select-item-option {
  padding: 4px 14px !important;
  min-height: 29px !important;
  align-items: center;
  &-content {
    display: block;
    height: 100%;
    line-height: 1.44;
    // font-weight: 500;
    font-size: variables.$form-fontSize;
    // font-weight: normal !important;
    color: variables.$form-fontColor;
    white-space: normal;
  }
  &-disabled {

    .ant-select-item-option-content {
      color: variables.$ebaq-gray3;
    }
  }
}

//FormTimePicker için yapıldı
.ant-picker {
  &-time-panel-column {
    @extend %scrollYBlue;
    width: 64px;
  }

  &-ranges {
    @extend %displayCenter;
  }

  &-now {
    border: 1px solid variables.$form-border;
    height: 24px;
    display: flex !important;
    align-items: center;
    padding: 0 5px;
    border-radius: 2px;

    &:hover {
      border: solid 1px variables.$form-border-focus;
    }

    &-btn {
      font-size: 14px;
      &:hover {
        color: #000;
      }
    }
  }

  &-ok {
    width: 100%;
    button {
      width: 100%;
      font-size: 15px;
      border: solid 1px variables.$form-border;
      color: variables.$form-fontColor;
      background-color: #ffffff;
      &:focus {
        border: solid 1px variables.$form-border-focus;
        background-color: transparent;
      }

      &:hover {
        border: solid 1px variables.$form-border-focus;
        color: variables.$form-fontColor;
        background-color: transparent;
      }
      span {
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }
}

// date picker
.ant-picker-today-btn {
  color: variables.$form-fontColor;

  &:hover {
    color: variables.$form-fontColor;
  }
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: variables.$form-datePicker-select-bg !important;
  }
}

.ant-picker-cell-today {
  .ant-picker-cell-inner::before {
    border-color: variables.$form-datePicker-select-bg !important;
  }
}

.ant-picker-dropdown {
  z-index: 99999 !important;
}

.ant-picker {
  &-separator{
    color: variables.$ebaq-orange!important;
  }

}
// dropdown

.ant-dropdown {
  z-index: 99999;
  ul {
    border-radius: 4px;
  }

  &-menu {
    padding: 0;
  }
}

//ant  button
%antBtnGeneralContainer {
  @extend %displayCenterCenter;
  // width: 100%;
  // min-height: 36px;
  // border-radius: 35px;

  padding: 0 17px;

  // padding: 10px 12px 10px 14px;
  border: none;
  text-shadow: none;
  box-shadow: none;

  // margin: 10px;

  &[disabled] {
    border-color: transparent !important;
    span {
      color: #fff;
    }
  }

  & span {
    font-size: 12px;
    font-weight: 500;
  }

  @include media.media479 {
    padding: 0 8px;
    & span {
      font-size: 11px;
      font-weight: 500;
    }
  }

}

//red
.ant-redBtn {
  background-color: #ea6452;
  @extend %antBtnGeneralContainer;
  border-radius: 4px;
  // border: 1px solid #E54C38;

  .icon {
    svg {
      font-size: 18px;
      color: #fff;
    }
  }
  & span {
    color: #fff;
  }
  &:hover,
  &:focus {
    // border: 1px solid #f16969;
    background-color: variables.$ebaq-red;
  }

  @include media.media479 {
    .icon {
      svg {
        font-size: 16px;
      }
    }
  }


}

.ant-redBtn-outline {
  background-color: #fff;
  @extend %antBtnGeneralContainer;
  border-radius: 4px;
  border: 1px solid #E54C38;

  &[disabled] {
    .icon {
      svg {
        color: white;
      }
    }
  }

  .icon {
    svg {
      font-size: 18px;
      color: variables.$ebaq-red;
    }
  }
  & span {
    color: variables.$ebaq-red;
  }
  &:hover,
  &:focus {
    border: 1px solid variables.$ebaq-red;
    background-color: variables.$ebaq-red;
    .icon {
      svg {
        color: #fff;
      }
    }
    & span {
      color: #fff;
    }
  }
}

$grayBtn-color: #9f9f9f;
$grayBtn-background-color: #b5b5b5;

.ant-grayBtn {
  background-color: $grayBtn-background-color;
  @extend %antBtnGeneralContainer;
  border-radius: 4px;
  border: 1px solid #a8a8a8;

  .icon {
    svg {
      font-size: 18px;
      color: #fff;
    }
  }
  & span {
    color: #fff;
  }
  &:hover,
  &:focus {
    border: 1px solid #9f9f9f;
    background-color: #a8a8a8;
  }

  @include media.media479 {
    .icon {
      svg {
        font-size: 16px;
      }
    }
  }

}

.ant-grayBtn-outline {
  background-color: #f1f1f1;
  @extend %antBtnGeneralContainer;
  border-radius: 4px;
  border: 1px solid #a8a8a8;

  .icon {
    svg {
      font-size: 18px;
      color: #8c8c8c;
    }
  }
  & span {
    // color: #fff;
    color: #8c8c8c;
  }
  &:hover,
  &:focus {
    border: 1px solid #9f9f9f;
    background-color: #a8a8a8;
    & span {
      color: #fff;
    }
    .icon {
      svg {
        color: #fff;
      }
    }

  }

  @include media.media479 {
    .icon {
      svg {
        font-size: 16px;
      }
    }
  }


}

// köşeli mavi
.ant-angularBlue {
  background-color: variables.$ebaq-blue6;
  @extend %antBtnGeneralContainer;
  // box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  // padding-left:8px;
  // padding-right:8px;

  .icon {
    svg {
      font-size: 16px!important;
      color: #fff;
    }
  }

  & span {
    color: #fff;
  }
  &:hover,
  &:focus {
    background-color: variables.$ebaq-blue6-focus;
  }
}

.ant-angularBlue-outline {
  background: #fff;
  @extend %antBtnGeneralContainer;
  border: 1px solid variables.$ebaq-blue6;
  border-radius: 4px;
  // padding-left:8px;
  // padding-right:8px;

  .icon {
    svg {
      font-size: 16px!important;
      color: variables.$ebaq-blue6;
    }
  }

  & span {
    color: variables.$ebaq-blue6;
  }
  &:hover,
  &:focus {
    color: #fff;
    background-color: variables.$ebaq-blue6-focus;
    span {
      color: #fff;
    }
    .icon {
      svg {
        color: #fff;
      }
    }

  }
}


.ant-loginBlue {
  background-color: #fff;
  @extend %antBtnGeneralContainer;
  // box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  height: 36px;
  border-radius: 18px;
  border: 0.5px solid variables.$ebaq-login-blue;
  padding:0;

  .icon {
    svg {
      font-size: 18px;
      color: #fff;
    }
  }

  & span {
    color: variables.$ebaq-login-blue;
    font-weight: 600;
    font-size: 13px;
  }
  &:hover,
  &:focus {
    background-color: variables.$ebaq-login-blue-focus;
    // border: 1px solid variables.$ebaq-orange;

    & span {
      color: #fff;
    }


  }

  @include media.media479 {
    .icon {
      svg {
        font-size: 16px;
      }
    }
  }

}

.ant-loginBlueSolid {
  background-color: variables.$ebaq-login-blue-focus;
  @extend %antBtnGeneralContainer;
  // box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  height: 36px;
  border-radius: 18px;
  border: 0.5px solid variables.$ebaq-login-blue;
  padding:0;

  .icon {
    svg {
      font-size: 18px;
      color: #fff;
    }
  }

  & span {
    color: #fff;
    font-weight: 600;
    font-size: 13px;
  }
  &:hover,
  &:focus {
    background-color: variables.$ebaq-login-blue;
    // border: 1px solid variables.$ebaq-orange;

    & span {
      color: #fff;
    }


  }
}


.ant-loginGoogle {

  background-color: #fff;
  @extend %antBtnGeneralContainer;
  // box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  height: 36px;
  border-radius: 18px;
  border: 0.5px solid variables.$google-blue;
  padding:0;

  // .icon {
  //   svg {
  //     font-size: 18px;
  //     color: #fff;
  //   }
  // }
  .icon {
    background-color: #fff;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: inline-flex;
    justify-content: center;
  }



  & span {
    color: variables.$google-blue;
    font-weight: 600;
    font-size: 13px;
  }
  &:hover,
  &:focus {
    background-color: variables.$google-blue;
    // border: 1px solid variables.$ebaq-orange;

    & span {
      color: #fff;
    }
    .icon {
      background-color: #fff;

    }
  }
}


.ant-loginGoogle[disabled] {
  background: red !important;
}


// köşeli mavi
.ant-angularRed {
  background-color: #E50F0F;
  @extend %antBtnGeneralContainer;
  // box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  & span {
    color: #fff;
  }
  &:hover,
  &:focus {
    background-color: #F44336;
    border-color: #F44336;
  }
}

// köşeli yeşil
.ant-angularGreen {
  @extend %antBtnGeneralContainer;
  background-color: variables.$ebaq-green;
  border-radius: 4px;
  border: 1px solid #0cb058;

  .icon {
    svg {
      font-size: 18px;
      color: #fff;
    }
  }
  & span {
    color: #fff;
    font-weight: 500;
    // font-size: 12px;
    // letter-spacing: normal;
  }
  &:hover,
  &:focus {
    background-color: #0cb058;
    border-color: #0cb058;
  }

  @include media.media479 {
    .icon {
      svg {
        font-size: 16px;
      }
    }
  }

}

// add button border olan
.ant-addBtn {
  background-color: #fff;
  @extend %antBtnGeneralContainer;
  border-radius: 16px;
  border: 1px solid #ec7200;
  & span {
    color: variables.$ebaq-orange;
    font-size: 11px;
    font-weight: 600;
    font-family: "Montserrat", Open sans;
  }
  &:focus {
    color: #ec7200;
    background-color: #fff; //rgb(255, 148, 65);
    border: 0.5px solid rgb(255, 148, 65);
  }
  &:hover {
    span {
      color: #fff;
    }
    .icon {
      // background-color: #fcefe5 !important;
      // color: $ebaq-orange;
      svg {
        color: #fff;
      }
    }
    background: variables.$ebaq-orange;
    background-color: variables.$ebaq-orange;
    border: 1px solid variables.$ebaq-orange-dark;
  }
  .icon {
    // background-color: #fcf0e8 !important;
    background-color: transparent!important;
    svg {
      color: variables.$ebaq-orange;
      margin-left: -0.5px;
      font-size: 16px!important;
    }
  }
  &[disabled] {
    .icon {
      svg {
        color: white!important;
      }
    }
  }
}


.orangeCircleBtn {
  background-color: #fff;
  // background-color: transparent;
  // @extend %antBtnGeneralContainer;
  border-radius: 16px;
  border: 1px solid #ec7200;
  & span {
    color: variables.$ebaq-orange;
    font-size: 11px;
    font-weight: 600;
    font-family: "Montserrat", Open sans;
  }
  &:focus {
    color: #ec7200;
    background-color: transparent; //rgb(255, 148, 65);
    border: 0.5px solid rgb(255, 148, 65);
  }
  &:hover {
    border: 1px solid #ec7200;
    span {
      color: #fff;

    }
    .icon {
      background-color: #fcefe5;
      // color: $ebaq-orange;
    }
    background: variables.$ebaq-orange;
    background-color: variables.$ebaq-orange;

    svg {
      color: #fff;
    }
  }
  .icon {
    background-color: #fcf0e8;
    // color: #fff;
  }

  svg {
    color: variables.$ebaq-orange;
  }


  font-size: 0px!important;

}

.orangeGrayCircleBtn {
  background-color: #fff;
  // background-color: transparent;
  // @extend %antBtnGeneralContainer;
  border-radius: 16px;
  border: 1px solid variables.$ebaq-gray6;

  & span {
    color: variables.$ebaq-gray6;
    font-size: 11px;
    font-weight: 600;
    font-family: "Montserrat", Open sans;
  }
  &:focus {
    color: #ec7200;
    background-color: transparent; //rgb(255, 148, 65);
    border: 0.5px solid rgb(255, 148, 65);
  }
  &:hover {
    background: variables.$ebaq-orange;
    border: 1px solid #ec7200;

    span {
      color: #fff;

    }
    .icon {
      // background-color: #fcefe5 !important;
    }

    svg {
      color: #fff;
    }
  }
  .icon svg{
    font-size: 1.2rem!important;
  }

  svg {
    color: variables.$ebaq-gray6;
  }


  font-size: 0px!important;

}


.ant-angularOrange {
  @extend %antBtnGeneralContainer;
  color: #212529;
  background-color: #e3892a;

  border-radius: 4px;
  border: 1px solid #e3892a;

  svg {
    color: #fff;
  }

  & span {
    color: #fff;
    font-weight: 500;
  }
  &:hover,
  &:focus {
    background-color: #f19638;
    border: 1px solid #f19638;
  }
}

.ant-angularYellow {
  @extend %antBtnGeneralContainer;
  color: #212529;
  background-color: variables.$ebaq-yellow;

  border-radius: 4px;
  border: 1px solid variables.$ebaq-yellow;


  & span {
    // color: #fff;
    font-weight: 500;
  }
  &:hover,
  &:focus {
    border: 1px solid #ffcc5c;
    background-color: #ffcc5c;
  }
}


.ant-whiteBtn {
  @extend %antBtnGeneralContainer;
  color: #212529;
  background-color: #fff;

  border-radius: 4px;
  border: 1px solid rgb(217, 217, 217);


  & span {
    // color: #fff;
    font-weight: 500;
  }
  &:hover {
    background-color: #fff;
    // color: variables.$ebaq-blue6;
    // background: $ebaq-orange;
    // border: 1px solid #ec7200;
    span {
      color: variables.$ebaq-blue6;
    }
    svg {
      color: variables.$ebaq-blue6;
    }
  }
  &:focus {
    border: 1px solid variables.$ebaq-blue6;
    background-color: #fff;
    color: variables.$ebaq-blue6;
  }
}

.ant-TurquoiseOutlineBtn {
  @extend %antBtnGeneralContainer;
  color: variables.$invitation-text-color;
  background-color: variables.$invitation-bg-color;

  border-radius: 4px;
  border: 1px solid variables.$invitation-border-color;


  & span {
    // color: #fff;
    font-weight: 500;
  }
  &:hover {
    background-color: variables.$invitation-bg-color;
    border: 1px solid variables.$invitation-border-dark-color;

    span {
      color: variables.$invitation-text-dark-color;
    }
    svg {
      color: variables.$invitation-text-dark-color;
    }
  }
  &:focus {
    border: 1px solid variables.$invitation-text-color;
    background-color: #fff;
    color: variables.$invitation-text-color;
  }
}



.ant-TableEbaqBtn {
  background-color: #fff;
  border-radius: 12px;
  color: #f19c4c;
  border: 1px solid #ec7200;

  svg {
    color: #f19c4c;
    width: 12px;
  }
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;

  text-shadow: 0 0 0 rgb(0 0 0 / 12%);
  box-shadow: 0 0 0 rgb(0 0 0 / 5%);


  span {
    color: variables.$ebaq-orange;
    margin-left: 6px;
    font-size: 10px;
    // letter-spacing: normal;
    // font-family: "Montserrat", Open sans;
  }

  &:focus {
    color: #ec7200;
    background-color: #fff; //rgb(255, 148, 65);
    border: 0.5px solid rgb(255, 148, 65);
  }
  &:hover {
    background-color: variables.$ebaq-orange;
    // background: $ebaq-orange;
    border: 1px solid #ec7200;
    span {
      color: #fff;
    }
    svg {
      color: #fff;
    }
  }

  &[disabled] {
    border-color: transparent !important;
    span {
      color: variables.$ebaq-orange;
    }
    svg {
      color: variables.$ebaq-orange;
    }
  }

}

// ant form  failed
.ant-form-item-explain {
  @extend %displayCenter;
  div {
    color: variables.$error-red;
    font-size: 12px;
    padding-bottom: 6px;
  }
}

.ant-form-item-has-error {
  .ant-input:hover,
  .ant-input:focus {
    box-shadow: none !important;
  }
}

// notification message
.ant-notification {
  z-index: 99999;
}

.ant-modal {
  &-mask {
    z-index: 9999;
  }
  &-wrap {
    z-index: 9999;
  }
}

.ant-table table { font-size: 30px; }

.ant-input {
  font-size: variables.$form-fontSize;
}

.ant-switch-checked {
  background-color: variables.$success-green;
}

.ant-image-img {
  border-radius: 4px
}


.identityAvatar {
  .ant-avatar-circle {
    display: flex;
    align-items: center;
    span {
      font-weight: 600;
    }
  }
}

.assignedIdentityColumn {
  .ant-avatar-circle {
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      font-weight: 600;
    }
  }
  /*
    .ant-popover-inner {
      border-radius: 5px;

      &-content {
        padding: 14px!important;
      }
      &-arrow {
        display: block;
      }
    }
    .ant-popover {
      padding-top: 0 !important;
      &-arrow {
        display: none;
      }
    }

   */

}

.form-preview-modal {
  background: #eeeeee!important;

  .ant-modal-body {
    padding: 0 !important;
  }
}

.wo-blue {
  color: variables.$ebaq-work-order-blue;
}


.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 6px 12px;
}

.ant-descriptions-item-label {
  span {
    color: #b5b5b5;
    font-size:10px;
  }
}
.ant-descriptions-item-content {
  span {
    font-weight: 600;
  }
}

.ant-tabs-ink-bar {
  background: variables.$ebaq-orange;
}

.ant-tabs-tab + .ant-tabs-tab {
  // margin: 0 0 0 32px;
  margin: 0 0 0 6px;

}

.ant-tabs-tab {
  padding: 6px 0;
}

.ageSpan {
  color: #b4b4b4;
}


.ant-pagination-item {
  display:inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-total-text {
  font-size:10px;
  color:gray;
  font-style: italic;
}

.clickable-color {
  color: variables.$ebaq-clickable-text;
}

.dt-title-sub {
  margin-top: 2px;
  color: #b1b1b1;
  font-size: 10px !important;
  span {
    font-size: 12px !important;
  }
}


.ant-tooltip{
  z-index: 10000;
  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding-bottom: 6px;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: gray;
  font-size: 0.8rem;
  min-height: 24px;
}

.ant-steps-item-description {
  font-size: 0.8rem;
}

.ant-steps-small .ant-steps-item-description {
  font-size: 0.8rem;
}


.ant-notification-bottomRight {
  bottom: 1px!important;
  margin-right: 8px;
}

.ant-notification{
  &-notice {
    width: 286px!important;
    padding: 6px;
    border-radius: 6px;
    margin-bottom: 8px;

    &-icon{
      margin-left: 3px;
      font-size: 13px;
      top: 9px;
    }
    &-message{
      display: flex;
      align-items: start;
      font-weight: 600;
      margin-left: 25px!important;
      margin-bottom: 0!important;
      span{
        font-size: 10px!important;
      }
    }

    &-description{
      margin-left: 25px!important;
      font-size: 11px;
    }

    &-close{
      top:4px;
      right: 10px;
      svg {
        font-size: 9px;
      }
    }

  }
}


.antCustomNotification {
  font-size: 10px;
  // background: red;
  padding: 6px;

  .ant-notification{

    &-notice {

      &-description{
        font-size: 11px;
        color: variables.$fontColor-gray;
      }
    }


  }
  //.ant-notification-notice {
  //	position: relative;
  //	width: 384px;
  //	max-width: calc(100vw - 24px * 2);
  //	margin-bottom: 16px;
  //	margin-left: auto;
  //	padding: 16px 24px;
  //	overflow: hidden;
  //	line-height: 1.5715;
  //	word-wrap: break-word;
  //	background: #fff;
  //	border-radius: 2px;
  //	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  //}
}

.billing-alerts {
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  // gap:6px;
  // padding: 6px;
  .ant-alert {
    margin-top: 6px;
    margin-left: 6px;
    margin-right: 6px;
  }
}


.deleteTagPopConfirm {
  .ant-popover-inner{
    padding: 6px 12px;
    border-top: 1px solid red;
    border-left: 1px solid red;
    border-right: 1px solid red;
    // border-bottom: 1px solid red;

    .ant-popover-message > .anticon {
      color: red;
      top: 2px;
    }

    .ant-popover-message-title {
      font-weight:600;
    }

    .ant-popover-buttons {
      display: flex;
      justify-content: space-between;
      gap: 6px;

      .ant-redBtn {
        margin: 0;
      }
      .ant-grayBtn {
        margin: 0;
      }
    }
  }
}

.ant-popover{
  z-index: 10001;
}

.ant-popover-inner {
  border-radius: 5px;

  &-content {
    padding: 0;
  }
  // &-arrow {
  //   display: none;
  // }
}
.ant-popover {
  padding-top: 0 !important;
  // &-arrow {
  //   display: none;
  // }
}

// .tagsInDt {
// padding: 0 !important;
//   display: flex;
//
// }




.login {
  background-color: variables.$bg-gray;
  min-height: 100vh;
  padding: 20px 20px 30px;
  @include media.media479 {
    padding: 12px 12px 12px;
  }

  .ant-angularBlue {
    height: 38px;
    border-radius: 4px;
    span {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: normal;
    }

  }
  // @extend %displayColumnCenter;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;


  &-container {
    border: 1px solid variables.$login-fontColor-primary-muted;
    padding: 20px;
    border-radius: 18px;
    background: white;
    max-width: 400px;


    @extend %displayColumnCenter;

    &__logo {
      // margin: 22px 0 22px;
      margin-top: 12px;
      // width: 264px;
      width: 194px;
      @include media.media767 {
        margin-bottom: 2px;
      }
    }
    &__title {

      font-weight: 600;
      font-size: 16px;
      // line-height: 38px;
      color: variables.$login-fontColor-primary;
      text-align: center;
      margin-bottom: 14px;
      /*
      @include media1200 {
        font-size: 24px;
      }
      @include media767 {
        font-size: 20px;
      }

       */
    }
    &__description {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      color: variables.$fontColor-lightGray;
      text-align: center;
      margin-bottom: 23px;
      @include media.media767 {
        font-size: 12px;
      }
    }

    &-rememberMe {
      width: 100%;
      margin-bottom: 12px;
      @extend %displayBetweenCenter;

      &-checkbox {

      }
      &-forget-pass {

      }

      &__forget {
        font-size: 12px;
        color: variables.$fontColor-orange;
        cursor: pointer;
        width: 120px;
      }
    }


    .formLogin .formElementLabel {
      color: #b9b9b9;
      font-style: italic;
      font-size: 10px;
      line-height: 15px;
      margin: 0 0 6px;
      display: flex;
      // justify-content: center;

    }

    .formTextInput__input {
      height: 38px;
      line-height: 19px;
      // border: 1px solid #f2f2f2;
      border: 1px solid #dddddd;
      border-radius: 18px;
    }

    .ant-divider-inner-text{
      color: #1c1c3861;
    }
    .formElementLabel__labelName{
      color: #1c1c3861;
    }

    .formCheckBox {
      width: 150px;

    }

    .ant-form-item {
      margin-bottom: 8px;
    }



    .formCheckBox__label {
      font-size: 12px;
      line-height: 1;
      color: #a6a6a6;
    }

    .formLogin .ant-form-item-explain {
      padding-left: 2px;
      font-size: 10px;
    }

    .formSubmitButton--custom {
      width: 100%;
    }

    &__login {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      span {
        text-decoration: underline;
        color: variables.$fontColor-orange;
        font-size: 12px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0.03em;
        cursor: pointer;
      }
    }
  }


  &-footer {
    // background-color: red;
    margin-top: auto;
    p {
      font-size: 10px;
      color: variables.$login-fontColor-primary;
      text-align: center;
    }
    a {
      font-size: 10px;
    }
  }
}



$login-fontColor-primary: #a2a2a2;
$login-fontColor-primary-muted: #dadada;

.lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: variables.$bg-gray;
  min-height: 100vh;
  padding: 10px 10px 30px;
  gap: 6px;

  @include media.media479 {
    padding: 12px 12px 12px;
  }

  .ant-angularBlue {
    height: 38px;
    border-radius: 4px;
    span {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: normal;
    }

  }
  // @extend %displayColumnCenter;
  // justify-content: space-between;

  &-container {
    border: 1px solid $login-fontColor-primary-muted;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-top: 12px;

    border-radius: 10px;
    background: white;
    min-width: 300px;
    max-width: 600px;
    @extend %displayColumnCenter;

    &__invitations_container {
      margin-top: 12px;
      background-color: variables.$invitation-bg-color;
    }

    &-invitation-item {
      display: flex;
      line-height: 1.15;
      padding: 4px 6px 4px 8px;

      // border: 0.5px solid $invitation-border-color;
      border: 0.5px solid variables.$invitation-border-color;
      border-radius: 4px;


      h5 {
        color: variables.$invitation-text-color;
        font-size: 12px!important;
        font-weight: 600!important;
      }


      &-policy {
        // background-color: #ffcfcf;
        color: variables.$ebaq-orange;
        display: flex;

        gap: 2px;
        margin-top: 3px;

        // padding-bottom: 2px;
        font-size: 10px;
        font-weight: 600;

        svg {
          font-size: 1rem;
          color: variables.$ebaq-orange;
        }

      }

      &-desc-container {
        // background-color: #ffcfcf;
        display: flex;
        flex-direction: column;
        flex: 1;

      }
      &-actions {
        // background-color: #b2fd96;
        display: flex;
        align-items: center;

        span {
          color: #fff;
        }

        .ant-btn-loading-icon {
          display: flex;
          justify-content:center;
        }

        &-ok-btn {
          background-color: rgb(76 175 79)!important;
          border-color: rgb(69, 159, 71);
          padding-left: 18px;
          padding-right: 18px;
          span{
            color: #fff;
            font-weight: 600;
          }
        }
        &-cancel-btn {
          span{
            font-size: 10px;
            color: #b4b4b4;
          }
        }
        &-refuse-btn {
          background-color: red !important;
          border-color: red;
          padding-left: 18px;
          padding-right: 18px;
          span{
            color: #fff;
            font-weight: 600;
          }
        }



      }


    }


    &__companies_container {

      // margin: 22px 0 22px;
      // margin-top: 12px;
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 200px;

      @include media.media767 {
        margin-bottom: 2px;
      }


      &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dadada;
        padding-bottom: 10px;
        // padding-top: 2px;
      }

      &_body {
        flex:1;
        display: flex;
        // align-items: center;
        flex-direction: column;
        padding-bottom: 16px;
      }

      &_footer {
        height: 32px;
        border-top: 1px solid #dadada;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-top: 10px;

      }

      &__no_company {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        gap: 18px;

        .muted{

          color: variables.$fontColor-lightGray;
          font-style: italic;

        }
      }

      &__description {
        // font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: variables.$fontColor-lightGray;
        text-align: center;
        // margin-bottom: 23px;
        @include media.media767 {
          font-size: 12px;
        }
      }
      &__description_secondary {
        // margin-top: 5px;
        font-size: 10px;
        color: variables.$fontColor-lightGray;
        // text-alfign: center;
        font-style: italic;
      }

      &__company {
        display: flex;
        align-items: center;
        margin-top: 8px;

        &__avatar {
          width: 30px;
        }

        &__policy_cont {
          font-size: 10px;
          font-weight: 500;
          margin-top: 0!important;
          svg {
            font-size: 0.8rem!important;
          }

        }

        &__company_name {
          flex:1;
          padding-left: 8px;
          font-weight: 500;

          .company_link {
            cursor: pointer;
            span {
              color: variables.$link-blue;
            }

          }

        }
      }

    }

    &__logo {
      // margin: 22px 0 22px;
      margin-top: 12px;
      width: 176px;
      @include media.media767 {
        margin-bottom: 2px;
      }
    }
    &__title {

      font-weight: 600;
      font-size: 16px;
      // line-height: 38px;
      color: variables.$login-fontColor-primary;
      text-align: center;
      margin-bottom: 14px;
      /*
      @include media1200 {
        font-size: 24px;
      }
      @include media767 {
        font-size: 20px;
      }

       */
    }
    &__description {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      color: variables.$fontColor-lightGray;
      text-align: center;
      margin-bottom: 23px;
      @include media.media767 {
        font-size: 12px;
      }
    }

    &-rememberMe {
      width: 100%;
      margin-bottom: 12px;
      @extend %displayBetweenCenter;
      &__forget {
        font-size: 12px;
        color: variables.$fontColor-orange;
        cursor: pointer;
      }
    }



    .formLogin .formElementLabel {
      color: #a6a6a6;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      margin: 0;

    }

    .formTextInput__input {
      height: 38px;
      line-height: 19px;
      border: 1px solid #a6a6a6;
    }

    .formCheckBox {
      width: 150px;


    }

    .ant-form-item {
      margin-bottom: 8px;
    }

    ant-form-item-control-input {
      border: 1px solid #a6a6a6;
    }

    .formCheckBox__label {
      font-size: 12px;
      line-height: 1;
      color: variables.$fontColor-gray;
    }

    .formLogin .ant-form-item-explain {
      padding-left: 2px;
      font-size: 10px;
    }

    // .formLogin {
    //   max-width: 353px;
    // }

    // .formTextInput {
    //   max-width: 353px;
    // }

    &__login {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      span {
        text-decoration: underline;
        color: variables.$fontColor-orange;
        font-size: 12px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0.03em;
        cursor: pointer;
      }
    }
  }
}

.engraved {
  font-size: 18px;
  font-weight: 600;
  // font-family: Futura;
  background-color: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(245,245,245,0.7) 0.5px 0.5px 0.5px;
}


.cursorPointer {
  cursor: pointer;
}

.schedular-dt-col{
  display: flex;
  flex-direction: column;

  .next-run-txt{
    display: flex;
    justify-content: end;
    font-size: 11px;
    .icon {
      svg {
        font-size: 10px;
      }
    }
  }

  .last-run-txt{
    font-size: 8px;
    display: flex;
    justify-content: end;
  }
}

.woCard{
  border: 1px solid #c0cffd !important;
  // border-radius: 4px!important;
  .customCard{
    &-header {
      // background: red;
      &-description{
        color: #7c96d1 !important;
      }
    }
  }

  .ant {
    &-card {
      &-head {
        background: #dce4ff !important;

        &-title {
          color: #3c5287 !important;
        }
      }
      &-body {
        background: #ecf1ff !important;
      }
    }
  }

  .workOrderListItem {
    transition: all 300ms ease-in-out;
    background: #f8f9ff;
    &:hover {
      box-shadow: 0 1.5px 1.5px rgba(0, 0, 0, 0.1);
    }
  }

}


.ant-addBlueBtn {
  background-color: #eff3ff;
  @extend %antBtnGeneralContainer;
  border-radius: 16px;
  border: 1px solid variables.$ebaq-work-order-blue;
  & span {
    color: variables.$ebaq-work-order-blue;
    font-size: 11px;
    font-weight: 600;
    font-family: "Montserrat", Open sans;
  }
  &:focus {
    color: #ec7200;
    background-color: #fff; //rgb(255, 148, 65);
    border: 0.5px solid rgb(255, 148, 65);
  }
  &:hover {
    span {
      color: #fff;
    }
    .icon {
      // background-color: #fcefe5 !important;
      // color: $ebaq-orange;
      svg {
        color: #fff;
      }
    }
    background: variables.$ebaq-orange;
    background-color: variables.$ebaq-orange;
    border: 1px solid variables.$ebaq-orange-dark;
  }
  .icon {
    // background-color: #fcf0e8 !important;
    background-color: transparent!important;
    svg {
      color: variables.$ebaq-work-order-blue;
      margin-left: -0.5px;
      font-size: 16px!important;
    }
  }
  &[disabled] {
    .icon {
      svg {
        color: white!important;
      }
    }
  }
}

.ant-addRedBtn {
  background-color: variables.$error-red-background;
  @extend %antBtnGeneralContainer;
  border-radius: 16px;
  border: 1px solid variables.$error-red;
  & span {
    color: variables.$error-red;
    font-size: 11px;
    font-weight: 600;
    font-family: "Montserrat", Open sans;
  }
  &:focus {
    color: #ec7200;
    background-color: #fff; //rgb(255, 148, 65);
    border: 0.5px solid rgb(255, 148, 65);
  }
  &:hover {
    span {
      color: #fff;
    }
    .icon {
      // background-color: #fcefe5 !important;
      // color: $ebaq-orange;
      svg {
        color: #fff;
      }
    }
    background: variables.$ebaq-orange;
    background-color: variables.$ebaq-orange;
    border: 1px solid variables.$ebaq-orange-dark;
  }
  .icon {
    // background-color: #fcf0e8 !important;
    background-color: transparent!important;
    svg {
      color: variables.$error-red;
      margin-left: -0.5px;
      font-size: 16px!important;
    }
  }
  &[disabled] {
    .icon {
      svg {
        color: white!important;
      }
    }
  }
}


.iconOnlyButton {
  max-width: 24px!important;

  .icon {
    margin-right: 0!important;
  }

  .ant-btn {
    padding-left: 6px!important;
    padding-right: 6px!important;
  }
}



.iconOnlySmButton {
  // max-width: 12px!important;
  // z-index: 9;
  // display: block;

  .ant-btn-sm {
    border-radius: 6.5px!important;
    padding: 0!important;
    height: 13px;
    width: 13px;

    .icon {
      margin: auto!important;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 8px!important;
        margin-left: auto!important;
        // margin-right: auto!important;
      }
    }

    .ant-addRedBtn {
      .icon{
        svg {
          // margin-left: 0!important;
          margin-right: auto!important;
        }
      }
    }

  }



  // .ant-addBtn .icon {
  //   // margin-right: 0!important;
  //   // font-size: 8px!important;
  // }
  // .ant-addBtn .icon svg {
  //   font-size: 8px!important;
  //   margin: auto!important;
  // }

  /*
  .ant-addBlueBtn .icon {
    margin-right: 0!important;
  }
  .ant-addBlueBtn .icon svg {
    font-size: 8px!important;
    margin: 0!important;
  }

  .ant-addRedBtn .icon {
    margin-right: 0!important;
  }
  .ant-addRedBtn .icon svg {
    font-size: 8px!important;
    margin: 0!important;
  }

   */


}

.antFormItemMarginCleared {
  .ant-form-item {
    margin-bottom: 0!important;
  }
}



.issueDrawer {

  .ant-drawer-header {
    background-color: #bb0000;
  }

}


.issueDetailDrawer {

  .ant-drawer-header {
    background-color: #bb0000;
  }

  .ant-drawer-body {
    padding: 0!important;
  }

  .issueCard {
    border: 0;
    margin-bottom: 0;

    .ant-card-body {
      padding: 0;
    }
  }

  &-padding {
    padding: 16px;
  }

  &-issue {
    padding: 16px;
    background-color: rgb(255, 248, 248);
    border-bottom: rgba(238, 0, 0, 0.42) solid 0.5px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

  }

}

.workOrderDrawer {

  .ant-drawer-header {
    background-color: variables.$ebaq-work-order-blue;
  }

}


.fileList{
  .ant-list-items {
    display: flex;
    flex-direction: column;
    gap:4px;
  }
}


.syncSchedular {
  .e-schedule .e-schedule-toolbar .e-tbar-btn .e-tbar-btn-text {
    font-size: 12px;
  }

  .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text {
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }

  .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-prev .e-icon-prev, .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-next .e-icon-next {
    font-size:14px
  }

  .e-schedule .e-schedule-toolbar .e-toolbar-items .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon.e-icon-down-arrow {
    font-size:14px
  }

  .e-header-cells {
    span {
      font-size: 10px;
    }
  }

  .e-schedule .e-month-view .e-appointment {
    cursor: pointer;
  }
}


/*
.entry-block {

  &-container-open {
    height: 157px;
    background-color: #e0e8ff;
    border-bottom: 1px solid #bfcef8;
    position: sticky;
    top: 0;
    // z-index: 100;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  &-container-closed {
    position: sticky!important;
    // z-index: 100!important;
    // height: 48px;
    top: 0!important;
    background-color: #f1f4fd;
    border-bottom: 1px solid #bfcef8;
    // see
    // https://getcssscan.com/css-box-shadow-examples
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

    .entry-block-leftBox{
      margin-right: 16px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-right: 1px solid #bfcef8;
    }
  }

  display: flex;
  justify-content: space-between;
  padding: 12px;

  &-leftBox {
    flex: 1;
    padding-right: 6px;
  }

  &-rightBox {
    display: flex;
    gap: 6px;
  }

  &-inspection {
    //position: absolute;
    // right: 0;
    width: 36px;
    // height: 133px;
    // margin-right: 12px;
    background: #FFFFFF;
    border: 1px solid rgba(74, 74, 255, 0.34);
    box-sizing: border-box;
    border-radius: 4px;
  }

  &-question {
    &-circle {
      // position: absolute;
      // right: 16px;
      width: 20px;
      height: 20px;
      background: white;
      border: 2px solid #BDBDBD;
      border-radius: 50%;
    }

  }


  &-title {
    text-decoration: underline;
    font-weight: 600;
    font-size: 18px;
    color: #5c6f9f;
  }

  &-description{
    // height: 32px;
    // font-style: normal;
    font-weight: 600;
    font-size: 12px;
    // line-height: 16px;
    color: #264294;
    padding-top: 8px;
    // padding-left: 24px;
    // padding-right: 76px;
  }

  &-infoBox {
    display: flex;
    padding-top: 20px;
    gap: 38px;

    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap:6px
    }

  }

  &-date{

    align-items: center;

    &-text {
      padding-left: 6px;
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #264294;
    }
  }

}


 */


.entry-block {

  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;

  &-container-open {
    // height: 157px;
    background-color: #e0e8ff;
    border-bottom: 1px solid #bfcef8;
    position: sticky;
    top: 0;
    // z-index: 100;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    justify-content: space-between;

    .entry-block-rightBox {
      align-items: flex-start;
    }

    @include media.media479 {
      .entry-block-rightBox {
        display: none;
      }

      .entry-block-infoBox-box {
        // justify-content: center;
        // align-items: center;
        width: 100%;
      }
    }

  }

  &-container-closed {
    position: sticky!important;
    z-index: 2!important;
    height: 48px;
    top: 0!important;
    background-color: #f1f4fd;
    border-bottom: 1px solid #bfcef8;
    // see
    // https://getcssscan.com/css-box-shadow-examples
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .entry-block-leftBox{
      // margin-right: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-right: 1px solid #bfcef8;
      margin-right: 12px;

      @include media.media479 {
        margin-right: 8px;

        .entry-block-title {
          font-size: 14px;
        }
      }
    }
  }

  &-leftBox {
    display: flex;
    flex: 1;
    padding-right: 6px;
    // align-items: center;
    flex-direction: column;

    &-header {
      display: flex;
      gap: 6px;

      &-titles {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      &-tags {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }

  }

  &-rightBox {
    display: flex;
    align-items: center;
    gap: 6px;

    @include media.media767 {
      // align-items: center;
    }
  }

  &-inspection {
    //position: absolute;
    // right: 0;
    width: 36px;
    // height: 133px;
    // margin-right: 12px;
    background: #FFFFFF;
    border: 1px solid rgba(74, 74, 255, 0.34);
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    &-trafficLights {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 4px;
    }
  }

  &-question {
    &-circle {
      // position: absolute;
      // right: 16px;
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 22px!important;
      height: 22px!important;
      // background: white;
      // border: 1px solid #BDBDBD;
      border-radius: 50%;

    }

  }


  &-title {
    // text-decoration: underline;
    font-weight: 600;
    font-size: 18px;
    color: variables.$ebaq-work-order-blue;
  }

  &-description{
    // height: 32px;
    // font-style: normal;
    // font-weight: 600;
    font-size: 12px;
    // line-height: 16px;
    color: rgba(73, 97, 153, 0.82);
    padding-top: 4px;
    // padding-left: 24px;
    // padding-right: 76px;
  }

  &-infoBox {
    display: flex;
    gap: 18px;
    margin-top: 12px;
    padding-top: 6px;
    border-top: 1px solid #d4dae8;

    @include media.media479 {
      flex-direction: column;
      align-items: center;

    }


    &-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // align-items: center;
      // gap:2px;

      &-desc {
        font-size: 0.8rem;
        color: #b3bbcd;
        font-style: italic;
      }

      &-val {
        font-size: 0.9rem;
        color: #264294;
      }

      .drawerResourceCard {
        min-width: 280px;
        margin-bottom: 0;
      }

      .wo-working-identities-container {
        margin-bottom: 0;
        padding: 2px;
        .wo-working-identities-identity-desc {
          color: #264294;
        }
      }

    }


  }

  &-date{

    align-items: center;

    &-text {
      padding-left: 6px;
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #264294;
    }
  }

}

.confirmWoActionModal {
  &-content{
    span, strong, i {
      font-size: 1.04rem;
    }
  }

  &-diagram {

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap:18px;
      padding:12px;

    }

    &-desc {
      display: block;
      text-align: center;
      padding:6px;
    }

    &-icon {
      svg {
        font-size: 48px!important;
      }
    }
  }
}


.wo-drawer-insp-card {
  .inspection-card-container {
    background: #ebefff;

    .inspection-card-title {
      border-bottom: 1px solid #e0e0e0;
    }

    .file-card {
      background: #ebefff;
    }

    .inspection-card-avatar {
      justify-content: flex-start;
    }

    .inspection-card-info-container {
      flex-direction: column;
    }
  }

}

.wo-drawer-insp-card-white {
  .inspection-card-container {


    .inspection-card-avatar {
      justify-content: flex-start;
    }

    .inspection-card-info-container {
      flex-direction: column;
    }
  }

}

.wo-insp-drawer-desc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  padding: 0 4px 12px 12px;
}

