@use "../../fileSass/variables";

.formElementLabel {
  display: block;
  margin-bottom: 6px;

  &__labelName {
    font-family: "Open Sans", serif;
    font-size: variables.$form-labelFontSize;
    color: variables.$form-labelColor;
  }

  &.required {
    &:after {
      content: " *";
      color: variables.$error-red;
    }
  }
}
