.formSubmitButton {
  width: 100%;
  position: relative;
  @extend %displayCenterCenter;
  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
  }
  .ant-row {
    width: 100%;
  }
  button {
    margin: auto;
  }

  &--custom {
    width: auto;

    &--rightIcon {
      .icon {
        margin-left: 6px;
        margin-right: 0!important;

      }
    }
  }



  .ant-btn {
    &[disabled] {
      background: lightGray !important;
    }
  }

  .icon {
    margin-right: 6px;
    svg {
      font-size: 14px;
    }
  }
}
