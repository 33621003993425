@use "../../fileSass/media";
@use "../../fileSass/variables";

.modalGeneral {
  padding: 0;
  border-radius: 0;
  // .ant-modal-content {
  //   .ant-modal-close,
  //   .ant-modal-footer {
  //     display: none;
  //   }
  // }

  .ant-modal-content {
  .ant-modal-close{
    display: none;
  }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }



  &-title {
    // @extend %displayJustCenter;
    // margin-left: -20px;
    // padding: 13px 0 15px 34px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-top: 12px;
    // padding-right: 18px;
    border-bottom: 1px solid rgba(255, 125, 4, 0.29);

    @include media.media767 {
      padding: 10px;
    }
    span {
      color: variables.$ebaq-orange;
      font-weight: 600;
      font-size: 14px;

    }

    &--right {
      justify-content: flex-end;
      margin-right: 20px;
    }

    &--left {
      justify-content: flex-start;
      margin-left: 0;
    }
  }

  &-close {
    position: absolute;
    z-index: 10;
    top: 12px;
    right: 12px;

    @include media.media767 {
        top: 10px;
        right: 10px;
      }

    .iconify {
      cursor: pointer;
      color: variables.$ebaq-orange;
      font-size: 18px;
      font-weight: 500;
    }

    &--left {
      left: 20px;
    }
  }

  &--backgroundClose {
    width: 100% !important;
    .ant-modal-body,
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
      width: 100%;
      padding: 0;
    }
  }

  &--closeBtnActive {
    .modalGeneral-whiteModal-container {
      //margin-top: 20px;
    }
  }

  &-whiteModal {
    overflow: hidden;
    box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
    // width: 900px !important;
    max-width: 95vw;
    // max-height: 90vh;

    .ant-modal-body {
      padding: 0;
    }
    .formResponsive-btn {
      margin-top: 15px;
    }

    &-container {
      @extend %displayColumn;
      @extend %scrollYBlue;
      overflow-x: hidden;
      padding: 12px;
      // margin-right: 5px;
      position: relative;
      // max-height: 70vh;
      // min-height: 100px;

      @include media.media767 {
        padding: 10px;
      }
    }
  }

  &--smallWidth {
    width: 500px !important;

    .formSubmitButton {
      .ant-row {
        margin-bottom: 0;
      }
    }
  }

  &--formCreate {

    width: 920px !important;
    margin-top: -50px !important;

    .formSubmitButton {
      .ant-row {
        margin-bottom: 0;
      }
    }

    .modalGeneral-whiteModal-container {
      padding: 0 !important;
    }
  }


  &--largeWidth {
    width: 1185px !important;

    .formSubmitButton {
      .ant-row {
        margin-bottom: 0;
      }
    }
  }

  &--fullSize {
    width: 100% !important;
    margin-top: -50px !important;

    .formSubmitButton {
      .ant-row {
        margin-bottom: 0;
      }
    }
  }

  &--withFooter {
    width: 750px !important;
    .ant-modal-content {
      .ant-modal-footer {
        display: block;
      }
    }
    .formResponsive-btn {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
