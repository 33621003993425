@use "../../fileSass/variables";

.formNumberMinusPlus {
  width: 130px;
  position: relative;

  .ant-input-number-focused {
    border: none !important;
    box-shadow: none !important;
  }
  .ant-form-item {
    width: 130px;
  }
  &-btns {
    position: absolute;
    top: 33px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__minus,
    &__plus {
      cursor: pointer;
      border: 1px solid #e0e0e0;
      padding: 10px;
      border-radius: 5px;
      @extend %slow400;
      .iconify {
        font-size: 16px;
        color: variables.$fontColor-darkBlue;
      }
      &:hover {
        border: solid 1px variables.$form-border-focus;
      }
    }
  }
  &__input {
    width: 50px;
    margin-left: 40px;
    margin-top: 6px;
    border-radius: 4px;
    border: none !important;
    position: relative;
    z-index: 1;

    input {
      width: 50px;
      background-color: variables.$form-bg;
      height: variables.$form-height;
      color: variables.$form-fontColor;
      line-height: variables.$form-height;
      font-size: variables.$form-fontSize;
      outline: none !important;
      text-align: center;
      font-weight: bold;
      line-height: 10px;
    }

    &:hover {
      border: none !important;
    }
  }
  &__inputLabel {
    position: absolute;
    left: 50%;
    z-index: 1;
    bottom: -8px;
    transform: translateX(-50%);
    font-weight: normal;
    font-size: 14px;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 10px;
  }

  .ant-form-item-has-error + .formNumberMinusPlus__inputLabel {
    bottom: 25px;
  }

  .ant-input-number-disabled {
    background-color: #f5f5f5;
  }

  .ant-input-number-handler-wrap {
    display: none;
    width: 0;
  }
}
