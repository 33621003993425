@use "../../fileSass/media";

.formLabelRadioGroup {
  width: 100%;

  &__radioGroup {
    margin-top: 5px;
    display: flex;
    width: 100%;
    @include media.media479 {
      flex-wrap: wrap;
    }
    label {
      @include media.media479 {
        margin-bottom: 5px;
      }
    }
  }
  &-item {
    > div {
      @extend %displayCenterCenter;
      // padding: 9px 0;
      // padding: 3px 4px;
      padding: 3px;
      border: 1px solid;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      min-width: 80px!important;

      span {
        color: inherit;
        font-size: 11px;
        font-weight: 600;
      }
    }
    &-selected {
      display: none !important;
      // span {
      //   color: white!important;
      // }
    }

  }
  .ant-radio {
    display: none;
    & + span {
      padding: 0;
    }
    &-wrapper {
      margin-right: 4px;
      &-checked {
        .formLabelRadioGroup-item {
        &-selected {
          display: flex !important;
        }
        &-notSelected {
          display: none;
        }
      }
      }
    }

  }
}
