.identityList {

  &-container {
    padding: 5px;
  }

  &-header {
    //background-color: green;
    display: flex;
    justify-content: space-between;

    .tableCustom-header-search__antd {
      margin-left: 0;
    }

    .searchInput {

      input {
        color: #1c1c38;
        font-size: 12px;
        border: none !important;
        box-shadow: none;
        border-color: transparent;
        width: calc(100% - 30px);
        margin-right: 2px;
        outline: none;
      }
    }
  }


  &-list {
    &-container {
      padding: 5px;
    }
    &-row {
      display: flex;
      padding-top: 4px;
      padding-bottom: 4px;


    }
    &-row:not(:last-child) {
     border-bottom: 1px solid rgba(222, 222, 222, 0.4);

      // .ant-avatar-group .ant-avatar:not(:first-child)

    }
    &-avatar {
      width: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &-identity {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      &-name {
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      &-policy {
        margin-top: 3px;
      }
    }
    &-actions {
      width: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

}
