@use "../../fileSass/variables";

.formDatePicker {
  // width: 280px;
  // min-width: 140px;
  .ant-picker-focused,
  .ant-picker:hover {
    box-shadow: none;
  }

  &__datePicker {
    display: block;
    height: variables.$form-height;
    // margin-top: 6px;
    border-radius: 4px;
    border: solid 1px variables.$form-border;
    background-color: variables.$form-bg !important;
    padding: 4px 12px !important;

    &:hover {
      border: solid 1px variables.$form-border-focus;
    }
    .anticon {
      font-size: 22px;
    }

    input {
      color: variables.$form-fontColor;
      line-height: 1.57;
      font-size: variables.$form-fontSize;
      height: 24px;
      line-height: variables.$form-height;
      border-right: 1px solid #ff9212 !important;
      margin-right: 5px;
    }
  }

  .ant-picker {
    @extend %displayCenter;
  }

  .ant-picker-suffix,
  .ant-picker-clear {
    svg {
      font-size: 14px;
    }
  }
}
