.icon {
  display: inline-flex;
  align-items: center;

  svg {
    font-size: 1.5rem;
  }
}

.plusIcon {
  background-color: black !important;
}
