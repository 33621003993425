@use "../../fileSass/variables";

.emptyCategoryContainer {
font-weight: 600;
  // .ant-empty-normal {
  //
  // }
  margin: 0;
  .ant-empty-description {
    display: none;
  }
}

.categoryListItemContainer {
  display: flex;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 48px;

  .categoryTitleContainer {
    font-size: 14px;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-right: 3px;

    .categoryTitle {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight:600;
      flex: 1;
      display: flex;
      align-items: center;
    }
    .categorySubTitle {
      flex: 1;
      overflow: auto;
      margin-top: 2px;

      span {
        font-size: 11px;
        color: #888888;
      }
    }

  }

  .categoryIconContainer {
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid variables.$ebaq-orange !important;
    cursor: pointer;

    .categoryIconRemove {
      font-size: 1.4rem;
      color: variables.$ebaq-orange;
    }
  }

}
