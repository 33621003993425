//font Color
$fontColor-primary: #626262;
$fontColor-blue: #264294;
$fontColor-darkBlue: #1c1c38;
$fontColor-orange: #ff7d04;
$fontColor-orange-muted-20: rgba(255, 146, 18, 0.2);
$fontColor-orange-muted-30: rgba(255, 146, 18, 0.3);
$fontColor-orange-muted-40: rgba(255, 146, 18, 0.4);
$fontColor-orange-muted-50: rgba(255, 146, 18, 0.5);
$fontColor-orange-muted-70: rgba(255, 146, 18, 0.7);
$fontColor-orange-muted-90: rgba(255, 146, 18, 0.9);
$fontColor-orange-muted-95: rgba(255, 146, 18, 0.95);
$fontColor-orange-white: rgba(255, 220, 185, 0.95);
$fontColor-gray: #4f4f4f;
$fontColor-midGray: #787676;
$fontColor-muted: #a8a5a5;
$fontColor-lightGray: #1c1c3861;
$fontColor-black: #1f1f1f;

$error-red: #d5393b;
$error-red-background: #fff2f4;
$error-red-border: #fdc7d2;
$success-green: #4caf50;
$warning-yellow: #faad14;
$info-blue: #1890ff;

//icon color
$icon-primary: #000;

//background color

$bg-gray: #f2f2f2;
$bg-light-gray: #f2f2f269;
$bg-lightBlue: #e0e8ff;
$bg-lightBlueBorder: #c5d1f8;
$bg-darkBlue: #20387f;
$bg-blue: #1c1c38;
$bg-darkGray: #ebebeb;
$bg-primary: #264294;
$bg-red: #e60f0f;
$bg-green: #0cc863;

//background - images

//border color
$border-blue: #264294;
$border-darkBlue: #1c1c38;

//scroll color

$scroll-primary: #264294;

//font family
// $fontFamily-primary: "Open Sans", sans-serif;
$fontFamily-primary: Open Sans, sans-serif , -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, 'Montserrat';

// $fontFamily-primary: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif !default;

//heigth width
$icon-HeigthWidth: 24px;

//font size
$fontSize-primary: 12px;
$fontSize-small: 10px;

// form settings
$form-fontColor: #000;
$form-bg: #fff;
$form-border: #e0e0e0;
$form-border-focus: #596b75;
$form-fontSize: 12px;
$form-labelColor: #a8a8a8;
$form-labelFontSize: 11px;
$form-smallLabelFontSize: 9px;
$form-height: 32px;
$form-textArea-height: 70px;
$form-input-margin-bottom: 14px;
$form-checkBox-bg: #002c5f;
$form-datePicker-select-bg: #002c5f;
$form-radio-select-bg: #002c5f;
$form-switch-bg: #002c5f;
$form-switch-bg-green: #0cc863;
$form-switch-bg-green-not-selected: #d7d7d7;
$form-switch-bg-red: #ff001f;


$ebaq-orange: #ff7d04;
$ebaq-orange-muted-70: rgba(255, 146, 18, 0.7);
$ebaq-orange-muted-50: rgba(255, 146, 18, 0.5);
$ebaq-orange-dark: #ec7201;
$ebaq-work-order-blue: #496199;
$ebaq-work-order-blue-muted: #8ca8f1;

$ebaq-light-blue: #7d99dc;
$ebaq-blue: #2e59be;
$ebaq-dark-blue: #142558;

$ebaq-green: #00b755;
$ebaq-red: #E54C38;
$ebaq-yellow: #ffc107;


$bg-privateLayout: linear-gradient(178.11deg, #07112e 1.6%, #264294 122.62%);
$left-menu-width: 220px;

$ebaq-gray2:  #4F4F4F;
$ebaq-gray25: #7c7a7a;
$ebaq-gray3: #989898;
$ebaq-gray4: rgba(189, 189, 189, 0.33);
$ebaq-gray5: #d7d7d7;
$ebaq-gray6: #bdbdbd;
$ebaq-gray7: #eaeaea;
$ebaq-gray8: #9a9a9a;
$ebaq-black: #1C1C38;
/* Daybreak Blue/6 */

$ebaq-clickable-text:#496199;
$ebaq-clickable-text-light: #7a92cb;
$ebaq-clickable-text-muted: #a7b3d0;
$ebaq-blue6:#1890FF;
$ebaq-blue6-focus: #1284ee;
$ebaq-login-blue:#254493;
$ebaq-login-blue-focus: #3759b2;
$modal-title-color: rgba(9, 109, 217, 0.77);

$login-fontColor-primary: #a2a2a2;
$login-fontColor-primary-muted: #dadada;

$drawer-background-color: #f1f1f1;

$border-dark: #a8a8a8;
$border-light: rgba(168, 168, 168, 0.4);


$invitation-color: #71c5ba;
$invitation-text-color: #62a8a0;
$invitation-text-dark-color: #569891;
$invitation-border-color: #d1f4f0;
$invitation-border-dark-color: #a1ccc6;
$invitation-bg-color: #effffe;


$delete-background-color: #ffe9e9;

$google-blue: #4286f5;

$link-blue: #6f90dd;

$issue-color: #bb0000;
$issue-background: rgb(255, 248, 248);